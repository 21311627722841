import React, { useState, useEffect, useContext } from "react";
import { Container, FormGroup, Label, Input, Row, Col } from "reactstrap";
import { useHistory } from "react-router-dom";
import { ServiceBookingContext } from '../../views/ServiceBooking';
import { GlobalContext } from '../../App';
import { getData } from "../../utils/localStorage";
// import { BIReducer, BIInitialState } from "reducer/bookingReducer";
import { GetAPI, PostAPI } from "../../API/APICalling";
import { removeData } from "../../utils/localStorage";
import { FnCreateProductList } from "../../utils/ObjectListFormat";
import { api } from "../../constant";
import { gtagConversionEvent } from "../../utils/common";

function BookingInformation(props) {
    const sbContext = useContext(ServiceBookingContext);
    const globalContext = useContext(GlobalContext);
    //const [state, dispatch] = useReducer(BIReducer, BIInitialState);
    const history = useHistory();
    // const [location, setLocation] = useState("");
    let arnum, email;

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        const session = getData('session');
        if (!session) {
            globalContext.notifications('error', 'Your session has been expired, Please login again.');
            history.push("/");
        }
        else {
            // console.log('Booking information');
            // console.log(sbContext.BookingState.PackageDetails)
            //dispatch({ type: 'SET_DATA', payload: sbContext.ServiceState.PackageDetails });//setting all data in local reducer
            FnGetLocation(session.arnum, session.email);
        }
    }, [])

    const FnGetLocation = async (arnum, email) => {
        globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
        var result = await GetAPI(`booking/locations/${arnum}?email=${email}`);
        if (result) {
            globalContext.loaderDispatch({ type: 'STOP_LOADING' });
            if (result.error) {
                sbContext.BookingDispatch({ type: 'ERROR' });
                globalContext.notifications('error', result.error);
            }
            else if (result.data) {
                sbContext.BookingDispatch({ type: 'LOCATION_SUCCESS', payload: result.data });
                // FnsetLocation(sbContext.BookingState.LocationData.shiplocationid);
                // console.log(result.data)
                // let defaultLocation = result.data.filter(function (item) {
                //     return item.isDefault == true
                // })[0];
                // if (defaultLocation != null) {
                //     setLocation(defaultLocation.locationid);
                // }
            }
        }
    };

    // const FnsetLocation = (location) => {
    //     // setLocation(location);
    //     console.log(sbContext.BookingState.LocationData)
    //     sbContext.BookingDispatch({ type: 'FIELD', field: 'shipaddr1', value: (Location['addr1'] || '') })
    //     //getting selected address.
    //     if (sbContext.BookingState.LocationData) {
    //         let Location = sbContext.BookingState.LocationData.filter(function (item) {
    //             return item.locationid === location
    //         })[0];
    //         if (Location === undefined) {
    //             // sbContext.BookingDispatch({ type: 'FIELD', field: 'shipaddr2', value: (Location['addr2'] || '') })
    //             sbContext.BookingDispatch({ type: 'FIELD', field: 'shipaddr3', value: 'Singapore' })
    //             sbContext.BookingDispatch({ type: 'FIELD', field: 'shippostcode', value: '' })
    //             sbContext.BookingDispatch({ type: 'FIELD', field: 'shipunitno', value: '' })
    //         }
    //         else {
    //             // sbContext.BookingDispatch({ type: 'FIELD', field: 'shipaddr1', value: (Location['addr1']).toString() })
    //             // sbContext.BookingDispatch({ type: 'FIELD', field: 'shipaddr2', value: (Location['addr2']).toString() })
    //             // sbContext.BookingDispatch({ type: 'FIELD', field: 'shipaddr3', value: (Location['addr3']).toString() })
    //             // sbContext.BookingDispatch({ type: 'FIELD', field: 'shippostcode', value: (Location['postcode']).toString() })
    //             sbContext.BookingDispatch({ type: 'FIELD', field: 'shipaddr1', value: (Location['addr1'] || '') })
    //             // sbContext.BookingDispatch({ type: 'FIELD', field: 'shipaddr2', value: (Location['addr2'] || '') })
    //             sbContext.BookingDispatch({ type: 'FIELD', field: 'shipaddr3', value: (Location['addr3'] || 'Singapore') })
    //             sbContext.BookingDispatch({ type: 'FIELD', field: 'shippostcode', value: (Location['postcode'] || '') })
    //             sbContext.BookingDispatch({ type: 'FIELD', field: 'shipunitno', value: (Location['unitno'] || '') })
    //         }
    //     }
    // }

    useEffect(() => {
        // setLocation(location);
        if (sbContext.BookingState.PackageDetails.shiplocationid) {
            // console.log(sbContext.BookingState.LocationData)
            // sbContext.BookingDispatch({ type: 'FIELD', field: 'shipaddr1', value: (Location['addr1'] || '') })
            //getting selected address.
            if (sbContext.BookingState.LocationData) {
                let Location = sbContext.BookingState.LocationData.filter(function (item) {
                    return item.locationid === sbContext.BookingState.PackageDetails.shiplocationid
                })[0];
                if (Location === undefined) {
                    sbContext.BookingDispatch({ type: 'FIELD', field: 'shipaddr1', value: '' })
                    sbContext.BookingDispatch({ type: 'FIELD', field: 'shipaddr3', value: 'Singapore' })
                    sbContext.BookingDispatch({ type: 'FIELD', field: 'shippostcode', value: '' })
                    sbContext.BookingDispatch({ type: 'FIELD', field: 'shipunitno', value: '' })
                }
                if (Location) {
                    // sbContext.BookingDispatch({ type: 'FIELD', field: 'shipaddr1', value: (Location['addr1']).toString() })
                    // sbContext.BookingDispatch({ type: 'FIELD', field: 'shipaddr2', value: (Location['addr2']).toString() })
                    // sbContext.BookingDispatch({ type: 'FIELD', field: 'shipaddr3', value: (Location['addr3']).toString() })
                    // sbContext.BookingDispatch({ type: 'FIELD', field: 'shippostcode', value: (Location['postcode']).toString() })
                    sbContext.BookingDispatch({ type: 'FIELD', field: 'shipaddr1', value: (Location['addr1'] || '') })
                    // sbContext.BookingDispatch({ type: 'FIELD', field: 'shipaddr2', value: (Location['addr2'] || '') })
                    sbContext.BookingDispatch({ type: 'FIELD', field: 'shipaddr3', value: (Location['addr3'] || 'Singapore') })
                    sbContext.BookingDispatch({ type: 'FIELD', field: 'shippostcode', value: (Location['postcode'] || '') })
                    sbContext.BookingDispatch({ type: 'FIELD', field: 'shipunitno', value: (Location['unitno'] || '') })
                }
            }
        }
        // else {
        //     sbContext.BookingDispatch({ type: 'FIELD', field: 'shipaddr1', value: '' })
        //     sbContext.BookingDispatch({ type: 'FIELD', field: 'shipaddr3', value: 'Singapore' })
        //     sbContext.BookingDispatch({ type: 'FIELD', field: 'shippostcode', value: '' })
        //     sbContext.BookingDispatch({ type: 'FIELD', field: 'shipunitno', value: '' })
        // }
        // if(!sbContext.BookingState.PackageDetails.guid){

    }, [sbContext.BookingState.PackageDetails.shiplocationid, sbContext.BookingState.LocationData]);



    const FnGetStreetAddress = async (postcode) => {
        if (postcode) {
            globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
            var response = await api.get(`https://www.onemap.gov.sg/api/common/elastic/search?searchVal=${postcode}&returnGeom=Y&getAddrDetails=Y`);
            if (response.data) {
                if (response.data.results.length > 0) {
                    sbContext.BookingDispatch({ type: 'FIELD', field: 'shipaddr1', value: response.data.results[0].ADDRESS || '' })
                }
                globalContext.loaderDispatch({ type: 'STOP_LOADING' });
                // if (result.error) {
                //     sbContext.BookingDispatch({ type: 'ERROR' });
                //     globalContext.notifications('error', result.error);
                // }
                // else if (result.data) {
                //     sbContext.BookingDispatch({ type: 'LOCATION_SUCCESS', payload: result.data });

                // }
            }
        }
    };

    const onSubmit = async (e) => {
        // console.log(state);
        if (e === 'DATE_TIME') {
            //no need to check anything//going back
            sbContext.ServiceDispatch({ type: e, payload: sbContext.BookingState.PackageDetails });
            return false;
        }
        const session = getData('session');
        if (!session) {
            globalContext.notifications('error', 'Your session has been expired, Please login again');
            history.push("/");
        }
        // || !sbContext.BookingState.PackageDetails.shippostcode || !sbContext.BookingState.PackageDetails.shipaddr1 || !sbContext.BookingState.PackageDetails.shipaddr3
        else if (!sbContext.BookingState.PackageDetails.shipfirstname || !sbContext.BookingState.PackageDetails.shiplastname || !sbContext.BookingState.PackageDetails.shipphone || !sbContext.BookingState.PackageDetails.shipemail || !sbContext.BookingState.PackageDetails.shippostcode || !sbContext.BookingState.PackageDetails.shipunitno || !sbContext.BookingState.PackageDetails.shipaddr1 || !sbContext.BookingState.PackageDetails.shipaddr3) {
            globalContext.notifications('error', 'Please fill all the details.');
            return false
        }
        else {
            arnum = session.arnum;
            email = session.email;
        }

        // if (sbContext.BookingState.PackageDetails._saveAddress) {
        //     if (!sbContext.BookingState.PackageDetails.shippostcode) {
        //         globalContext.notifications('error', 'Postal code is required.');
        //         return false
        //     }
        //     if (!sbContext.BookingState.PackageDetails.shipunitno) {
        //         globalContext.notifications('error', 'Unit number is required.');
        //         return false
        //     }
        //     if (!sbContext.BookingState.PackageDetails.shipaddr3) {
        //         globalContext.notifications('error', 'Country is required.');
        //         return false
        //     }
        // }

        globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
        var result = await PostAPI(`booking/cart/${arnum}?email=${email}`, FnCreateProductList([sbContext.BookingState.PackageDetails]));
        if (result) {
            globalContext.loaderDispatch({ type: 'STOP_LOADING' });
            if (result.error) {
                sbContext.BookingDispatch({ type: 'ERROR' });
                globalContext.notifications('error', result.error);
            }
            else if (result.data) {
                sbContext.BookingDispatch({ type: 'SUCCESS' });
                //going forword
                if (!sbContext.BookingState.PackageDetails.guid) {
                    //Not allow user to go back even after page refresh
                    for (var i = 0; i < 100; i++) {
                        history.push({
                            pathname: '/service-booking',
                            state: { 'redirect': 'FINALIZE' }
                        })
                    }
                    //going forward
                    e && sbContext.ServiceDispatch({ type: e });
                }
                else {
                    e && sbContext.ServiceDispatch({ type: e });
                }
                removeData('transferredData');
                removeData('questionnaire');
                gtagConversionEvent("AW-730252635/XggDCLym8s8CENuKm9wC");
            }
        }
    }
    return (
        <>
            <Row>
                <Col className="ml-auto mr-auto pt-10 pb-30" xl="9" lg="10" md="12">
                    <div className="card">
                        <div className="card-header text-left title-2">
                            Booking Information
                                 </div>
                        <div className="card-body p-0-xs">
                            <Container>
                                <Row>
                                    <Col md="8" className="text-left">
                                        <p className="title-3">Contact Information</p>
                                        <p className="description">This Information will be used to contact you about your service.</p>
                                        
                                        <FormGroup className="text-left col-md-6 pl-0">
                                            <Label className="badge badge-neutral">Salutation:</Label>
                                            <select className="form-control"
                                                value={sbContext.BookingState.PackageDetails.shiptitle || ''}
                                                onChange={e => {
                                                    sbContext.BookingDispatch({
                                                        type: 'FIELD',
                                                        field: 'shiptitle',
                                                        value: e.currentTarget.value,
                                                    })
                                                }}>
                                                <option value="">Salutation</option>
                                                <option value="Mr">Mr </option>
                                                <option value="Ms">Ms</option>
                                                <option value="Mrs">Mrs</option>
                                            </select>
                                        </FormGroup>

                                        <FormGroup>
                                            <Input type="text" className="form-control" placeholder="First Name*"
                                                maxLength={15}
                                                value={sbContext.BookingState.PackageDetails.shipfirstname || ''}
                                                onChange={e =>
                                                    sbContext.BookingDispatch({
                                                        type: 'FIELD',
                                                        field: 'shipfirstname',
                                                        value: e.currentTarget.value
                                                    })
                                                }
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Input type="text" className="form-control" placeholder="Last Name*"
                                                maxLength={"15"}
                                                value={sbContext.BookingState.PackageDetails.shiplastname || ''}
                                                onChange={e =>
                                                    sbContext.BookingDispatch({
                                                        type: 'FIELD',
                                                        field: 'shiplastname',
                                                        value: e.currentTarget.value,
                                                    })
                                                } />
                                        </FormGroup>
                                        <FormGroup>
                                            <Input type="number" className="form-control" placeholder="Phone number*"
                                                value={sbContext.BookingState.PackageDetails.shipphone || ''}
                                                onChange={e =>
                                                    sbContext.BookingDispatch({
                                                        type: 'NUMBER',
                                                        digit: 10,
                                                        field: 'shipphone',
                                                        value: e.currentTarget.value,
                                                    })
                                                }
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Input type="text" className="form-control" placeholder="E-mail*"
                                                maxLength={"50"}
                                                value={sbContext.BookingState.PackageDetails.shipemail || ''}
                                                onChange={e =>
                                                    sbContext.BookingDispatch({
                                                        type: 'FIELD',
                                                        field: 'shipemail',
                                                        value: e.currentTarget.value,
                                                    })
                                                }
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>

                                {sbContext.BookingState.LocationData &&
                                    <React.Fragment>
                                        {sbContext.BookingState.LocationData.length === 0 ? (
                                            ''
                                        ) : (
                                                <React.Fragment>
                                                    <Row>
                                                        <Col md="12" className="ml-auto mr-auto">
                                                            <p className="title-3">Service Location</p>
                                                            <p className="description">Where will we send  our service crew?</p>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col md="12" className="pr-30 pl-30 ml-auto mr-auto text-center">
                                                            <Row>
                                                                {
                                                                    sbContext.BookingState.LocationData.map((item, index) => (
                                                                        <Col key={index} xl="6" lg="6" md="12" sm="12" className="text-center pl-5 pr-5">
                                                                            <button
                                                                                className={sbContext.BookingState.PackageDetails.shiplocationid === item.locationid ? "btn btn-location active" : "btn btn-location"}
                                                                                onClick={e => {
                                                                                    e.preventDefault();
                                                                                    //FnsetLocation(item.locationid);
                                                                                    sbContext.BookingDispatch({
                                                                                        type: 'FIELD',
                                                                                        field: 'shiplocationid',
                                                                                        value: item.locationid
                                                                                    });
                                                                                }}>
                                                                                <b className="mb-10">{item.locationname}</b>
                                                                                <span>{item.fulladdr}</span>
                                                                            </button>
                                                                        </Col>
                                                                    ))
                                                                }
                                                            </Row>
                                                        </Col>
                                                    </Row>
                                                </React.Fragment>
                                            )
                                        }
                                    </React.Fragment>
                                }
                                {/* <Row>
                                    <Col md="12" className="ml-auto mr-auto">
                                        <p className="title-3">Service Location</p>
                                        <p className="description">Where will we send  our service crew?</p>
                                        <Row>
                                            <Col md="6" sm="12" className="text-center">
                                                <button
                                                    className={location === "1" ? "btn btn-location active" : "btn btn-location"}
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        setLocation("1");
                                                    }}>
                                                    <b className="mb-10">Address 1</b>
                                                    <span>987654, 01 Streat Name #01-01 Singapore</span>
                                                </button>
                                            </Col>
                                            <Col md="6" sm="12" className="text-center">
                                                <button
                                                    className={location === "2" ? "btn btn-location active" : "btn btn-location"}
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        setLocation("2");
                                                    }}>
                                                    <b className="mb-10">Address 2</b>
                                                    <span>987654, 01 Streat Name #01-01 Singapore</span>
                                                </button>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row> */}
                                <Row>
                                    <Col md="8" className="text-left">
                                        <p className="title-3">Service Address?</p>
                                        <p className="description">Create on below</p>

                                        <FormGroup>
                                            <Input type="number" className="form-control col-md-6" placeholder="Postal Code*"
                                                value={sbContext.BookingState.PackageDetails.shippostcode || ''}
                                                onBlur={e => FnGetStreetAddress(e.currentTarget.value)}
                                                onChange={e => {
                                                    sbContext.BookingDispatch({
                                                        type: 'NUMBER',
                                                        digit: 6,
                                                        field: 'shippostcode',
                                                        value: e.currentTarget.value,
                                                    });
                                                    sbContext.BookingDispatch({
                                                        type: 'FIELD',
                                                        field: 'shiplocationid',
                                                        value: ''
                                                    });
                                                }
                                                }
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Input type="text" className="form-control" placeholder="Address Line 1"
                                                maxLength={"100"}
                                                value={sbContext.BookingState.PackageDetails.shipaddr1 || ''}
                                                onChange={e => {
                                                    sbContext.BookingDispatch({
                                                        type: 'FIELD',
                                                        field: 'shipaddr1',
                                                        value: e.currentTarget.value,
                                                    });
                                                    sbContext.BookingDispatch({
                                                        type: 'FIELD',
                                                        field: 'shiplocationid',
                                                        value: ''
                                                    });
                                                }
                                                }
                                            />
                                        </FormGroup>
                                        {/* <FormGroup>
                                            <Input type="text" className="form-control" placeholder="Address Line 2"
                                                maxLength={"100"}
                                                value={sbContext.BookingState.PackageDetails.shipaddr2 || ''}
                                                onChange={e =>
                                                    sbContext.BookingDispatch({
                                                        type: 'FIELD',
                                                        field: 'shipaddr2',
                                                        value: e.currentTarget.value,
                                                    })
                                                }
                                            />
                                        </FormGroup> */}
                                        <FormGroup>
                                            <Input type="text" className="form-control col-md-6" placeholder="Unit Number*"
                                                value={sbContext.BookingState.PackageDetails.shipunitno || ''}
                                                maxLength={"10"}
                                                onChange={e => {
                                                    sbContext.BookingDispatch({
                                                        type: 'FIELD',
                                                        field: 'shipunitno',
                                                        value: e.currentTarget.value,
                                                    });

                                                    sbContext.BookingDispatch({
                                                        type: 'FIELD',
                                                        field: 'shiplocationid',
                                                        value: ''
                                                    });
                                                }
                                                }
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <select className="form-control col-md-6"
                                                style={{
                                                    textAlignLast: "center"
                                                }}
                                                value={sbContext.BookingState.PackageDetails.shipaddr3 || ''}
                                                onChange={e => {
                                                    sbContext.BookingDispatch({
                                                        type: 'FIELD',
                                                        field: 'shipaddr3',
                                                        value: e.currentTarget.value,
                                                    });
                                                    sbContext.BookingDispatch({
                                                        type: 'FIELD',
                                                        field: 'shiplocationid',
                                                        value: ''
                                                    });
                                                }
                                                }
                                            >
                                                <option value="">Select Country</option>
                                                <option value="Singapore">Singapore</option>
                                                <option value="United Kingdom">United Kingdom</option>
                                            </select>
                                        </FormGroup>
                                        <FormGroup check>
                                            <Label check>
                                                <Input type="checkbox"
                                                    checked={sbContext.BookingState.PackageDetails._saveAddress === undefined ? true : sbContext.BookingState.PackageDetails._saveAddress}
                                                    onChange={e =>
                                                        sbContext.BookingDispatch({
                                                            type: 'FIELD',
                                                            field: '_saveAddress',
                                                            value: e.currentTarget.checked,
                                                        })
                                                    }
                                                />
                                                <span className="form-check-sign description"></span>
                                                Save This Address to My Profile
                                                    </Label>
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Container>
                            <hr></hr>
                            <Container>
                                <Row>
                                    <Col md="12" className="text-left">
                                        <p className="title-3">Notes for Crew</p>
                                        <p className="description">Any remarks you want our service crew to know?</p>
                                        <FormGroup>
                                            <Input type="textarea" className="form-control" placeholder="e.g. Please pay more attentionto kitchen area"
                                                maxLength={"250"}
                                                value={sbContext.BookingState.PackageDetails.remarks || ''}
                                                onChange={e =>
                                                    sbContext.BookingDispatch({
                                                        type: 'FIELD',
                                                        field: 'remarks',
                                                        value: e.currentTarget.value,
                                                    })
                                                }
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </Container>
                        </div>
                        <div className="card-footer text-center">
                            <button
                                className={"btn btn-round btn-default"}
                                onClick={e => {
                                    e.preventDefault();
                                    // props.Function.FnSetRenderComponentType("DATE_TIME");
                                    onSubmit('DATE_TIME');
                                }}>
                                PREVIOUS
                                     </button>
                            <button
                                className={"btn btn-round btn-info"}
                                onClick={e => {
                                    e.preventDefault();
                                    // props.Function.FnSetRenderComponentType("FINALIZE_BOOKING");
                                    onSubmit('FINALIZE_BOOKING');
                                }}>
                                CONTINUE
                              </button>
                        </div>
                    </div>
                </Col>
            </Row>
        </>
    );
}

export default BookingInformation;
