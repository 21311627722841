import React, { useState, useContext, useEffect } from "react";

// reactstrap components
import {
  //Card,
  Container,
  Row,
  Col
} from "reactstrap";
import { GetAPI } from "../../API/APICalling";
import { GlobalContext } from '../../App.js';
import { ReadMore } from '../../utils/common';

// core components

export default function FreeTextSection() {
  const globalContext = useContext(GlobalContext);
  const [data, setData] = useState({});

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    const FnGetCMSContent = async () => {
      globalContext.loaderDispatch({ type: 'START_LOADING', payload: false });
      var result = await GetAPI(`cms/home-body-4`);
      console.log("AAAAAAAAAAAAAAAAAAAAAAAAAAAA")
      if (result) {
        console.log(result)
        globalContext.loaderDispatch({ type: 'STOP_LOADING' });
        if (result.error) {
          //globalContext.notifications('error', result.error);
        }
        else if (result.data) {
          console.log(result.data)
          setData(result.data.content)
        }
      }
    }
    FnGetCMSContent();
  }, []);

  return (
    <>
      {data.body &&
        <ReadMore>{data.body}</ReadMore>
      }
    </>
  );
}

