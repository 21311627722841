import React, { useContext, useReducer, useEffect, useState } from 'react';
import { Button, ModalBody, ModalHeader, Form, Row, Col, Container, InputGroup, Input, InputGroupAddon, InputGroupText } from 'reactstrap';
import { GlobalContext } from '../../App.js';
import Loader from './Loader';
import { LoginInitialState, LoginReducer } from '../../reducer/LoginReducer';
import { storeData } from '../../utils/localStorage';
import { GetAPI, PostAPI } from '../../API/APICalling';
import SocialLogin from './SocialLogin';
import { useHistory } from 'react-router-dom';

import { getUserSubscription } from "../../utils/push-notifications";
import { isiOS } from '../../utils/common';
//import all the function created to manage the push notifications
import { ENABLELOGIN } from "../../constant";

function ModalLogin(props) {
    const globalContext = useContext(GlobalContext);
    const [loginState, loginDispatch] = useReducer(LoginReducer, LoginInitialState)
    const { isLoading, username, password } = loginState;
    const history = useHistory();

    useEffect(() => {
        document.querySelector('.modal').scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);

    const FnLogin = async e => {
        // loginDispatch({ type: 'LOGIN' });

        if (!username) {
            globalContext.notifications('error', 'UserId is required.');
            return false
        }
        if (!password) {
            globalContext.notifications('error', 'Password is required.');
            return false
        }
        var req = {};
        if (!isiOS()) {
            var abc = await getUserSubscription();
            var data = JSON.parse(JSON.stringify(abc, null, " "));
            if (data) {
                req = {
                    Endpoint: data.endpoint, P256DH: data.keys.p256dh, Auth: data.keys.auth
                }
            }
        }

        globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
        var result = await PostAPI(`user/login?userEmail=${username}&pwd=${password}&deviceType=web&deviceToken=${JSON.stringify(req)}`);
        if (result) {
            if (result.error) {
                loginDispatch({ type: 'ERROR' });
                globalContext.notifications('error', result.error);
                globalContext.loaderDispatch({ type: 'STOP_LOADING' });
            }
            else if (result.data) {
                loginDispatch({ type: 'SUCCESS', payload: result.data });
                FnGetCartBadgeCount(result.data.arnum, result.data.email);
                globalContext.authDispatch({ type: 'SET', payload: result.data });
                globalContext.notifications('success', 'Login successfully.');
                storeData('session', result.data);
                globalContext.loaderDispatch({ type: 'STOP_LOADING' });
                // history.replace('/Index');

                if (globalContext.headerState.loginFromHeader) {
                    globalContext.headerDispatch({ type: 'CLOSE' });
                }
                else {
                    if (globalContext.headerState.skipqn) {
                        history.push({
                            pathname: '/package-listing',
                            state: { 'matcatnum': globalContext.headerState.matcatnum, 'displayaddon': globalContext.headerState.displayaddon }
                        })
                    }
                    else {
                        globalContext.headerDispatch({ type: 'CHOOSELOCATION_OLDUSER', matcatnum: globalContext.headerState.matcatnum })
                    }
                    // globalContext.headerDispatch({ type: 'CHOOSELOCATION_OLDUSER', matcatnum: globalContext.headerState.matcatnum });
                }
            }
        }
    };

    const FnGetCartBadgeCount = async (arnum, email) => {
        var result = await GetAPI(`booking/cart/summary/${arnum}?email=${email}`);
        if (result) {
            if (result.error) {
                globalContext.notifications('error', result.error);
            }
            else if (result.data) {
                globalContext.commonDispatch({ type: 'SET_CART', payload: result.data });
                console.log(result.data)
            }
        }
    }

    return (
        <>
            {isLoading ? <Loader /> : null}
            <div className="login-modal">
                <ModalHeader toggle={() => globalContext.headerDispatch({ type: 'CLOSE' })}>
                </ModalHeader>
                <ModalBody>
                    <Container>
                        <Row>
                            <Col xl="6" lg="6" md="12" className="ml-auto mr-auto ">
                                <Form>

                                    <div className="form-group text-center">
                                        <img
                                            alt="Whissh Image"
                                            src={require("../../assets/img/icon-login.png")}
                                            style={{ height: "50px" }}
                                        >
                                        </img>
                                    </div>
                                    <div className="form-group text-center">
                                        <b className="title-3">
                                            Log in here with your credential
                                          </b>
                                    </div>
                                    <hr></hr>
                                    <InputGroup>
                                        <Input
                                            placeholder="Email"
                                            type="text"
                                            value={username}
                                            onChange={e =>
                                                loginDispatch({
                                                    type: 'FIELD',
                                                    field: 'username',
                                                    value: e.currentTarget.value,
                                                })
                                            }
                                        ></Input>
                                        <InputGroupAddon addonType="append">
                                            <InputGroupText>
                                                <i className="fab now-ui-icons users_circle-08"></i>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                    </InputGroup>
                                    <InputGroup>
                                        <Input
                                            placeholder="Password"
                                            type="Password"
                                            value={password}
                                            onChange={e =>
                                                loginDispatch({
                                                    type: 'FIELD',
                                                    field: 'password',
                                                    value: e.currentTarget.value
                                                })
                                            }
                                            required
                                        ></Input>
                                        <InputGroupAddon addonType="append">
                                            <InputGroupText>
                                                <i className="fa fa-key"></i>
                                            </InputGroupText>
                                        </InputGroupAddon>
                                    </InputGroup>

                                    

                                    {/* <Button type="button" className="btn-round btn btn-info btn-block" onClick={() => globalContext.headerDispatch({ type: 'OTP' })}>LOG IN</Button> */}
                                    <Button disabled={isLoading} type="button" className="btn-round btn btn-info btn-block" onClick={() => FnLogin()}>
                                        {
                                            isLoading ? (
                                                "Loading..."
                                            ) : (
                                                    "LOG IN"

                                                )}
                                    </Button>

                                    <div className="form-group text-center">
                                        <h6 className="pull-left">
                                            <Button className="btn-link btn-info pl-0"
                                                onClick={() => globalContext.headerDispatch({ type: 'SIGNUP' })}
                                            >Sign up?</Button>
                                        </h6>
                                        <h6 className="pull-right">
                                            <Button className="btn-link btn-info pr-0"
                                                onClick={() => globalContext.headerDispatch({ type: 'FORGOT_PASSWORD' })}
                                            >Forgot your password</Button>
                                        </h6>
                                    </div>
                                    
                                    {(ENABLELOGIN.facebook || ENABLELOGIN.google) &&
                                        <React.Fragment>
                                            <hr></hr>
                                            <p className="text-center">
                                                Or
                                      </p>
                                            <SocialLogin data={{ getUserSubscription: getUserSubscription }} />

                                        </React.Fragment>
                                    }
                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </ModalBody>
            </div>

        </>
    );

}

export default ModalLogin;