import React, { useEffect, useContext, useReducer } from "react";
import { Link, useHistory } from "react-router-dom";
import { Container, Row, Col, FormGroup, Label } from "reactstrap";
import MyAccountHeader from "../../components/MyAccount/MyAccountHeader";
import { GlobalContext } from '../../App';
import { MyRequestReducer, MyRequestInitialState } from "../../reducer/MyAccountReducer";
import { getData } from "../../utils/localStorage";
import { GetAPI, DeleteAPI } from "../../API/APICalling";
import { DateFormat } from "../../utils/common";

export default function MyRequest() {
    const globalContext = useContext(GlobalContext);
    const [state, dispatch] = useReducer(MyRequestReducer, MyRequestInitialState)
    const history = useHistory();

    useEffect(() => {
        FnGetMyRequests();
    }, []);

    const FnGetMyRequests = async () => {
        const session = getData('session');
        if (!session) {
            globalContext.notifications('error', 'Your session has been expired, Please login again.');
            history.push("/");
        }
        else {
            globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
            var result = await GetAPI(`myrequest/${session.arnum}`);
            if (result) {
                globalContext.loaderDispatch({ type: 'STOP_LOADING' });
                if (result.error) {
                    globalContext.notifications('error', result.error);
                }
                else if (result.data) {
                    // console.log(result.data)
                    dispatch({ type: 'GET_SUCCESS', payload: result.data });
                }
            }
        }
    };

    const FnDeleteQuote = async (refnum) => {
        const session = getData('session');
        if (!session) {
            globalContext.notifications('error', 'Your session has been expired, Please login again.');
            history.push("/");
        }
        else {
            if (refnum) {
                globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
                var result = await DeleteAPI(`myrequest/${refnum}`);
                if (result) {
                    globalContext.loaderDispatch({ type: 'STOP_LOADING' });
                    if (result.error) {
                        dispatch({ type: 'ERROR' });
                        globalContext.notifications('error', result.error);
                    }
                    else {
                        globalContext.notifications('success', 'Quotation deleted successfully.');
                        FnGetMyRequests();
                    }
                }
            }
        }
    }

    return (
        <>
            <div className="adjust-navbar-breadcrumb">
                <div className="breadcrumb-outer">
                    <Container className="breadcrumb-body">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item">
                                <Link to="/">
                                    Home
                                    </Link>
                            </li>
                            <li className="breadcrumb-item">
                                <Link to="/my-account/my-profile">
                                    <i className="fa fa-angle-right pr-5" aria-hidden="true"></i>
                                    My Account
                                    </Link>
                            </li>
                            <li className="breadcrumb-item active">
                                <i className="fa fa-angle-right pr-5" aria-hidden="true"></i>
                                My Request
                            </li>
                        </ol>
                    </Container>
                </div>
                <div className="myaccount-header">
                    <MyAccountHeader />
                </div>
                <Container fluid id="myAccount">
                    <Row>
                        <Col className="ml-auto mr-auto text-left" xl="10" lg="12" md="12">
                            <Row className="pt-30">
                                <Col xl="10" lg="12" md="12" className="ml-auto mr-auto">
                                    <h2 className="big-heading">My Request</h2>
                                </Col>
                            </Row>
                            <Row>
                                <Col xl="10" lg="12" md="12" className="ml-auto mr-auto">
                                    <Row className="mt-15">
                                        <Col md="12" className="ml-auto mr-auto">
                                            <div className="card" id="myRequest">
                                                <div className="card-body">
                                                    <div>
                                                        <Row>
                                                            <Col md="12" className="text-left">
                                                                <Label className="title-3">Type of Request</Label>
                                                            </Col>
                                                            <Col md="4" sm="12" xs="12">
                                                                <FormGroup>
                                                                    <select className="form-control"
                                                                        style={{
                                                                            textAlignLast: "center"
                                                                        }}
                                                                        onChange={e =>
                                                                            dispatch({
                                                                                type: 'FILTER_RESULT',
                                                                                value: e.currentTarget.value,
                                                                            })
                                                                        }>
                                                                        <option value="">All</option>
                                                                        {state.RequestTypeDdl.map((item, index) => (
                                                                            <option key={index} value={item}>{item}</option>
                                                                        ))}
                                                                    </select>
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <div className="table-responsive table-list-view" id="myRequestTable">
                                                        <table className="table table-striped">
                                                            <thead>
                                                                <tr>
                                                                    <th className="text-center">Ticket No.</th>
                                                                    <th className="text-left">Service Type</th>
                                                                    <th className="text-center">Request Date</th>
                                                                    <th className="text-center">Status</th>
                                                                    <th className="text-center">Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>

                                                                {state.FilterRequestResult &&
                                                                    <React.Fragment>
                                                                        {
                                                                            state.FilterRequestResult.length < 1 ?
                                                                                <tr>
                                                                                    <td colSpan="5" className="text-center empty-screen">No Records Found</td></tr>
                                                                                :
                                                                                <React.Fragment>
                                                                                    {
                                                                                        state.FilterRequestResult.map((item, index) => (
                                                                                            <tr key={index}>
                                                                                                <td className="text-center">{item.ticket}</td>
                                                                                                <td className="text-left">{item.servicetype}</td>
                                                                                                <td className="text-center">{DateFormat(item.requesetdate, 'DD-MMM-YYYY')}</td>
                                                                                                <td className="text-center confirmed">{item.status}</td>
                                                                                                <td className="text-center">
                                                                                                    {item.viewasquote &&
                                                                                                        <span className="pr-10">
                                                                                                            <Link to={`/my-request-quotation/${item.refnum}`} tag={Link}>
                                                                                                                <img
                                                                                                                    alt="Whissh Image"
                                                                                                                    className="rounded-circle"
                                                                                                                    src={require("../../assets/img/icon-sm-blue-view.png")}
                                                                                                                    style={{ maxHeight: "18px", paddingRight: "10px" }}
                                                                                                                ></img>
                                                                                                            </Link>
                                                                                                        </span>
                                                                                                    }
                                                                                                    <span>
                                                                                                        <Link to='#'
                                                                                                            onClick={e => {
                                                                                                                e.preventDefault();
                                                                                                                FnDeleteQuote(item.refnum);
                                                                                                            }}>
                                                                                                            <img
                                                                                                                alt="Whissh Image"
                                                                                                                className="rounded-circle"
                                                                                                                src={require("../../assets/img/icon-sm-blue-cancel.png")}
                                                                                                                style={{ maxHeight: "14px" }}
                                                                                                            ></img>
                                                                                                        </Link>
                                                                                                    </span>
                                                                                                </td>
                                                                                            </tr>
                                                                                        ))
                                                                                    }
                                                                                </React.Fragment>
                                                                        }
                                                                    </React.Fragment>
                                                                }
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}
