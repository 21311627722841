import React, { useEffect, useState, useContext } from "react";

// reactstrap components
import {
  Container, Button, Row, Col, NavItem, NavLink, FormGroup, Input, Carousel,
  CarouselItem,
  CarouselIndicators
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
// https://www.npmjs.com/package/react-accessible-accordion
import "../../assets/css/accordion.css";
import { ReadMore } from '../../utils/common.js'


import { GetAPI, PostAPI } from "../../API/APICalling";
import { GlobalContext } from '../../App.js';
import { ValidateEmail, getLink, getAlt } from "../../utils/common";
import HomepageContent from "../ContentPages/Homepage";

export default React.memo(function OurServicesDetails(props) {
  const [active, setActive] = React.useState(props.match.params.type);
  const globalContext = useContext(GlobalContext);
  const [data, setData] = React.useState('');
  const [breadcrumb, setBreadcrumb] = React.useState(props.match.params.type || '');
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const [formData, setFormData] = useState({});
  const history = useHistory();

  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === (data.body_carousel.length || 1) - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? (data.body_carousel.length || 1) - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = newIndex => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    const FnGetService = async () => {
      globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
      var result = await GetAPI(`cms/ourservice/${props.match.params.type}`);
      if (result) {
        globalContext.loaderDispatch({ type: 'STOP_LOADING' });
        if (result.error) {
          globalContext.notifications('error', result.error);
        }
        else if (result.data) {
          // console.log(result.data.content)
          setData(result.data.content)
        }

        if (result.data == null) {
          history.push({
            pathname: `/404`
          })
        }
      }
    }
    FnGetService();

    if (props.location.state) {
      setBreadcrumb(props.location.state.title || '');
    }

  }, [props.match.params.type]);

  const FnRedirectDecision = (type, matcatnum) => {
    // console.log("matcatnum: " + matcatnum)
    // console.log("skipqn: " + skipqn)
    // console.log("displayaddon: " + displayaddon)
    if (globalContext.authState.isLoggedIn) {
      // if (skipqn) {
      //   history.push({
      //     pathname: '/package-listing',
      //     state: { 'matcatnum': matcatnum, 'displayaddon': false }
      //   })
      // }
      // else {
      globalContext.headerDispatch({ type: 'CHOOSELOCATION_OLDUSER', matcatnum: matcatnum })
      // }
    }
    else {
      globalContext.headerDispatch({ type: 'CHOOSE_SERVICE', matcatnum: matcatnum, skipqn: false, displayaddon: false });
    }
  };

  const FnRedirectToOurService = (pageid, title) => {
    console.log("pageid: " + pageid)
    console.log("title: " + title);
    setActive(pageid);
    if (pageid) {
      history.push({
        pathname: `/${pageid}`,
        state: { 'title': title }
      })
    }
  };


  const FnQuestionDecision = (matcatnum, skipqn, displayaddon, btnlink) => {
    // console.log("matcatnum: " + matcatnum)
    // console.log("skipqn: " + skipqn)
    // console.log("displayaddon: " + displayaddon)

    if (btnlink) {
      globalContext.headerDispatch({ type: 'CLOSE' });
      history.push({
        pathname: btnlink,
      });
    }
    else if (skipqn) {
      history.push({
        pathname: '/package-listing',
        state: { 'matcatnum': matcatnum, 'displayaddon': displayaddon }
      })
    }
    else {
      if (globalContext.authState.isLoggedIn) {
        globalContext.headerDispatch({ type: 'CHOOSELOCATION_OLDUSER', matcatnum: matcatnum })
      }
      else {
        globalContext.headerDispatch({ type: 'CHOOSE_SERVICE', matcatnum: matcatnum, skipqn: skipqn, displayaddon: displayaddon });
      }
    }
  };


  const FnContactUs = async (e) => {
    e.preventDefault();
    if (!formData.ptc) {
      globalContext.notifications('error', 'Name is required.');
      return false;
    }
    if (!formData.ptcemail) {
      globalContext.notifications('error', 'Email address is required.');
      return false;
    }
    if (!ValidateEmail(formData.ptcemail)) {
      globalContext.notifications('error', 'You have entered an invalid email address.');
      return false
    }
    else {
      if (formData) {
        globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
        var result = await PostAPI(`home/contactus`, formData);
        if (result) {
          globalContext.loaderDispatch({ type: 'STOP_LOADING' });
          if (result.error) {
            globalContext.notifications('error', result.error);
          }
          else {
            setFormData({});
            globalContext.notifications('success', 'Your request has been sumitted successfully.');
          }
        }
      }
    }
  }


  const FnReturnHtml = (section) => {
    switch (section) {
      case 'body_freetext': {
        return (
          data.body_freetext &&
          <Row>
            {/* <Col md="12" dangerouslySetInnerHTML={{ __html: data.body_freetext }}></Col> */}
            <ReadMore>
              {data.body_freetext}
            </ReadMore>
          </Row>
        );
      }
      case 'body_carousel': {
        return (
          data && data.body_carousel &&
          data.body_carousel.length !== 0 &&
          <Row>
            <Col md="12">
              <React.Fragment>
                <Carousel
                  activeIndex={activeIndex}
                  next={next}
                  previous={previous}
                >
                  <CarouselIndicators
                    items={data.body_carousel}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                  />
                  {data.body_carousel.map((item, index) => {
                    return (
                      <CarouselItem
                        onExiting={onExiting}
                        onExited={onExited}
                        key={index}
                      // className="home-carousel-inner"
                      >
                        <img src={getLink(item.image)} alt={getAlt(item.image)} />
                        {/* <div className="carousel-caption d-none d-md-block">
                          <h5>{item.caption}</h5>
                        </div> */}
                        {/* <div className="carousel-caption d-none d-md-block"> */}
                        {/* <div className="carousel-caption">
                  <h5>{item.caption}</h5>
                </div> */}
                      </CarouselItem>
                    );
                  })}
                  <a
                    className="carousel-control-prev"
                    data-slide="prev"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      previous();
                    }}
                    role="button"
                  >
                    <i className="now-ui-icons arrows-1_minimal-left"></i>
                  </a>
                  <a
                    className="carousel-control-next"
                    data-slide="next"
                    href="#pablo"
                    onClick={e => {
                      e.preventDefault();
                      next();
                    }}
                    role="button"
                  >
                    <i className="now-ui-icons arrows-1_minimal-right"></i>
                  </a>
                </Carousel>
                {/* <div className="home-carousel-caption">
              <h5>{item.caption}</h5> 
           </div> */}
                <hr></hr>
              </React.Fragment>
            </Col>
          </Row>
        );
      }
      case 'body_accordion_title': {
        return (
          data.body_accordion_title &&
          <Row>
            <Col md="12">
              <div className="mt-20">
                <p className="title-2 text-center">{data.body_accordion_title}</p>
              </div>
            </Col>
          </Row>
        );
      }
      case 'body_accordion': {
        return (
          data && data.body_accordion &&
          data.body_accordion.length !== 0 &&
          <Row>
            <Col md="12">
              <React.Fragment>
                {/* <div className="mt-20">
                    <p className="title-2 text-center">Accordion Header</p>
                  </div> */}
                <Accordion allowZeroExpanded={true}>
                  {data.body_accordion.map((item, index) => (
                    <AccordionItem key={index}>
                      <AccordionItemHeading>
                        <AccordionItemButton><h3 style={{ display: "inline", color: "#1f70db", fontSize: "14px", marginBottom: "0", fontWeight: "600" }}>{item.header}</h3></AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel>
                        <p className="description" dangerouslySetInnerHTML={{ __html: item.content }}></p>
                        {/* <p className="description">{item.content}</p> */}
                      </AccordionItemPanel>
                    </AccordionItem>
                  ))}
                </Accordion>
                <hr></hr>
              </React.Fragment>
            </Col>
          </Row>
        );
      }
      case 'body_prod': {
        return (
          data && data.body_prod &&
          data.body_prod.length !== 0 &&
          data.body_prod.map((item, index) => (
            <React.Fragment key={index} >
              <Row>
                <Col md="12" dangerouslySetInnerHTML={{ __html: item.html }}></Col>
              </Row>
              <Row>
                <Col md="12" className="ml-auto mr-auto">
                  <div className="text-left">
                    <Button
                      type="button" className="btn-round btn btn-info"
                      // onClick={() => FnRedirectDecision(item.btnlink, item.catnum)}
                      onClick={() => FnQuestionDecision(item.catnum, item.skipqn, undefined, item.btnlink)}
                    >
                      <i className="fas fa-bell"></i>
                      <span className="bookNow">{item.btn}</span>
                    </Button>
                  </div>
                  <hr></hr>
                </Col>
              </Row>
            </React.Fragment>
          ))
        );
      }
      default:
        return '';
    }
  }

  return (
    <>
      <div className="adjust-navbar-breadcrumb">
        {breadcrumb != 'search' && <div className="breadcrumb-outer">
          <Container className="breadcrumb-body">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">
                  Home
                </Link>
              </li>
              <li className="breadcrumb-item active">
                <Link to="/our-services/index">
                  <i className="fa fa-angle-right pr-5" aria-hidden="true"></i>
                  Service
                </Link>
              </li>
              <li className="breadcrumb-item active">
                <i className="fa fa-angle-right pr-5" aria-hidden="true"></i>
                {breadcrumb}
              </li>
            </ol>
          </Container>
        </div>}
        {breadcrumb == 'search' && <HomepageContent />
        }
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="12">
              <Row>
                <Col className="ml-auto mr-auto text-left" md="8">
                  {data.title &&
                    <React.Fragment>
                      <div>
                        <h1 className="big-heading text-center">
                          {data.title}
                        </h1>
                      </div>
                      <hr className="hr-large"></hr>
                    </React.Fragment>
                  }
                  {data.desc &&
                    <div>
                      <h2 className="description text-center pb-30">
                        {data.desc}
                      </h2>
                    </div>
                  }
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
        {data.header_desc &&
          <Container fluid className="pb-20">
            <Row>
              <Col className="ml-auto mr-auto text-center" md="12">
                <Row className="about-us-heading">
                  <Col md="6" sm="12" xs="12">
                    <img
                      alt={getAlt(data.header_image)}
                      className="pull-right"
                      src={getLink(data.header_image)}
                    ></img>
                  </Col>
                  <Col md="6" sm="12" xs="12" dangerouslySetInnerHTML={{ __html: data.header_desc }}>

                    {/* <div className="text-left pt-20">
                    <h5 className="title-2">Who are we?</h5>
                    <p className="description">
                      Whissh is a one-stop home service provider. Backed by a global investment initiative, we strive to transform and improve the quality of home living.
                        <br /> <br />
                      Our wide array of services includes home cleaning, office cleaning, laundry, plumbing, aircon servicing, electrical maintenance and other related services.
                    </p>
                  </div> */}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        }
        <Container>
          <Row>
            <Col className="text-left ml-auto mr-auto" md="9">

              {data && data.orderby &&
                data.orderby.length !== 0 &&
                data.orderby.map((item, index) => {
                  return (
                    <React.Fragment key={index}>
                      {FnReturnHtml(item.section)}
                    </React.Fragment>
                  )
                })
              }








              {/* <Row>
                <Col md="12" className="ml-auto mr-auto">
                  <div className="text-left">
                    <h5 className="title-2">Spring Cleaning</h5>
                    <p className="description">
                      Certain occasions demand that you seek professional help for cleaning your house
            </p>
                  </div>
                  <div className="text-left">
                    <h5 className="title-3">For Whom ?</h5>
                    <p className="description">
                      You’ve got things to do that are more important than cleaning a messy house. Let us take care of it! Our team of experts provides trustworthy, high-quality home cleaning services.
            </p>
                  </div>
                  <div className="text-left">
                    <h5 className="title-3">Cleaning Scope</h5>

                    <b><span className="right-icon"></span>Living Halls and bedrooms</b>
                    <p className="pl-25 description">
                      Dust all accessible surface, dust celling fans, empty trash bins, wipe cabinets (exterior), wipe built-in wardrobe (exterior), vacuum carpets, sweep/vacuum floor and mop floor.
            </p>
                    <b><span className="right-icon"></span>Kitchen</b>
                    <p className="pl-25 description">

                      Clean appliance (exterior only), clean refrigerator (exterior), clean kitchen hob (stove), wash and scrub sink, wash and scrub, faucets, wipe counters, wipe cabinets (interior), sweep/vacuum floor, mop floor.
            </p>

                    <b><span className="right-icon"></span>Bathroom</b>
                    <p className="pl-25 description">
                      Clean mirrors, wash and scrub shower area, wash and scrub toilet bowl, wash and scrub sinks, wash and scrub faucets, wash and scrub floors, wash and scrub toilet wall tiles
            </p>
                  </div>

                  <div className="text-left">
                    <Button
                      type="button" className="btn-round btn btn-info"
                      to="/service-booking" tag={Link}
                    >
                      <i className="fas fa-bell"></i>
                      <span className="bookNow">Book Now</span>
                    </Button>
                  </div>
                  <hr></hr>
                </Col>
              </Row> */}
              {/* <Row>
                <Col md="12" className="ml-auto mr-auto ">
                  <div className="text-left">
                    <h5 className="title-2">Move In/Out Cleaning</h5>
                    <p className="description">
                      Certain occasions demand that you seek professional help for cleaning your house
                   </p>
                  </div>
                  <div className="text-left">
                    <h5 className="title-3">For Whom ?</h5>
                    <p className="description">
                      You’ve got things to do that are more important than cleaning a messy house. Let us take care of it! Our team of experts provides trustworthy, high-quality home cleaning services.
                   </p>
                  </div>
                  <div className="text-left">
                    <h5 className="title-3">Cleaning Scope</h5>

                    <b><span className="right-icon"></span>Living Halls and bedrooms</b>
                    <p className="pl-25 description">
                      Dust all accessible surface, dust celling fans, empty trash bins, wipe cabinets (exterior), wipe built-in wardrobe (exterior), vacuum carpets, sweep/vacuum floor and mop floor.
                  </p>
                    <b><span className="right-icon"></span>Kitchen</b>
                    <p className="pl-25 description">

                      Clean appliance (exterior only), clean refrigerator (exterior), clean kitchen hob (stove), wash and scrub sink, wash and scrub, faucets, wipe counters, wipe cabinets (interior), sweep/vacuum floor, mop floor.
                  </p>

                    <b><span className="right-icon"></span>Bathroom</b>
                    <p className="pl-25 description">
                      Clean mirrors, wash and scrub shower area, wash and scrub toilet bowl, wash and scrub sinks, wash and scrub faucets, wash and scrub floors, wash and scrub toilet wall tiles
                   </p>
                  </div>

                  <div className="text-left">
                    <Button
                      type="button" className="btn-round btn btn-info"
                      to="/service-booking" tag={Link}
                    >
                      <i className="fas fa-bell"></i>
                      <span className="bookNow">Book Now</span>
                    </Button>
                  </div>
                  <hr></hr>
                </Col>
              </Row>
              <Row>
                <Col md="12" className="ml-auto mr-auto ">
                  <div className="text-left">
                    <h5 className="title-2">Post Renovation Cleaning</h5>
                    <p className="description">
                      Certain occasions demand that you seek professional help for cleaning your house
                  </p>
                  </div>
                  <div className="text-left">
                    <h5 className="title-3">For Whom ?</h5>
                    <p className="description">
                      You’ve got things to do that are more important than cleaning a messy house. Let us take care of it! Our team of experts provides trustworthy, high-quality home cleaning services.
                 </p>
                  </div>
                  <div className="text-left">
                    <h5 className="title-3">Cleaning Scope</h5>

                    <b><span className="right-icon"></span>Living Halls and bedrooms</b>
                    <p className="pl-25 description">
                      Dust all accessible surface, dust celling fans, empty trash bins, wipe cabinets (exterior), wipe built-in wardrobe (exterior), vacuum carpets, sweep/vacuum floor and mop floor.
                   </p>
                    <b><span className="right-icon"></span>Kitchen</b>
                    <p className="pl-25 description">

                      Clean appliance (exterior only), clean refrigerator (exterior), clean kitchen hob (stove), wash and scrub sink, wash and scrub, faucets, wipe counters, wipe cabinets (interior), sweep/vacuum floor, mop floor.
                    </p>

                    <b><span className="right-icon"></span>Bathroom</b>
                    <p className="pl-25 description">
                      Clean mirrors, wash and scrub shower area, wash and scrub toilet bowl, wash and scrub sinks, wash and scrub faucets, wash and scrub floors, wash and scrub toilet wall tiles
                  </p>
                  </div>

                  <div className="text-left">
                    <Button
                      type="button" className="btn-round btn btn-info"
                      to="/service-booking" tag={Link}
                    >
                      <i className="fas fa-bell"></i>
                      <span className="bookNow">Book Now</span>
                    </Button>
                  </div>
                  <hr></hr>
                </Col>
              </Row> */}

            </Col>
            <Col className="ml-auto mr-auto text-left d-none d-sm-block" md="3">
              <div>
                <div className="links service-right-menu">
                  <ul className="stacked-links">
                    {
                      globalContext.commonState.megamenuitems.map((item, index) => (
                        <React.Fragment key={index}>
                          {item.subservices.map((item2, index2) => (
                            <NavItem key={index2} className={active === item2.prodpageid ? "active" : ""}>
                              {/* <NavLink
                                href="#"
                                onClick={e => {
                                  e.preventDefault();
                                  setActive(item2.subsvcnum);

                                }}
                              > */}
                              <NavLink to="#" tag={Link}
                                // to={{
                                //   pathname: `${item2.prodpageid}`,
                                //   state: { 'title': item2.subsvcname }
                                // }}
                                onClick={e => {
                                  e.preventDefault();
                                  //setActive(item2.prodpageid);
                                  FnRedirectToOurService(item2.prodpageid, item2.subsvcname)
                                }}
                              >
                                <i className={active === item2.prodpageid ? "fa fa-angle-right pr-15" : ""}></i>
                                {item2.subsvcname}
                              </NavLink>
                            </NavItem>
                          ))
                          }
                        </React.Fragment>
                      ))
                    }

                    {/* <NavItem className={active === "1" ? "active" : ""}>
                      <NavLink
                        href="#"
                        onClick={e => {
                          e.preventDefault();
                          setActive("1");
                        }}
                      >
                        <i className={active === "1" ? "fa fa-angle-right pr-15" : ""}></i>
                         Propert Management
                       </NavLink>
                    </NavItem>
                    <NavItem className={active === "2" ? "active" : ""}>
                      <NavLink
                        href="#"
                        onClick={e => {
                          e.preventDefault();
                          setActive("2");
                        }}
                      >
                        <i className={active === "2" ? "fa fa-angle-right pr-15" : ""}></i>
                          Conceirge
                                                    </NavLink>
                    </NavItem>
                    <NavItem className={active === "3" ? "active" : ""}>
                      <NavLink
                        href="#"
                        onClick={e => {
                          e.preventDefault();
                          setActive("3");
                        }}
                      >
                        <i className={active === "3" ? "fa fa-angle-right pr-15" : ""}></i>
                          Home Cleaning
                                                     </NavLink>
                    </NavItem>
                    <NavItem className={active === "4" ? "active" : ""}>
                      <NavLink
                        href="#"
                        onClick={e => {
                          e.preventDefault();
                          setActive("4");
                        }}
                      >
                        <i className={active === "4" ? "fa fa-angle-right pr-15" : ""}></i>
                         Laundary
                                                     </NavLink>
                    </NavItem>
                    <NavItem className={active === "5" ? "active" : ""}>
                      <NavLink
                        href="#"
                        onClick={e => {
                          e.preventDefault();
                          setActive("5");
                        }}
                      >
                        <i className={active === "5" ? "fa fa-angle-right pr-15" : ""}></i>
                          Air Servicing
                         </NavLink>
                    </NavItem>
                    <NavItem className={active === "6" ? "active" : ""}>
                      <NavLink
                        href="#"
                        onClick={e => {
                          e.preventDefault();
                          setActive("6");
                        }}
                      >
                        <i className={active === "6" ? "fa fa-angle-right pr-15" : ""}></i>
                          Plumbing
                         </NavLink>
                    </NavItem>
                    <NavItem className={active === "7" ? "active" : ""}>
                      <NavLink
                        href="#"
                        onClick={e => {
                          e.preventDefault();
                          setActive("7");
                        }}
                      >
                        <i className={active === "7" ? "fa fa-angle-right pr-15" : ""}></i>
                          Electrical
                         </NavLink>
                    </NavItem>
                    <NavItem className={active === "8" ? "active" : ""}>
                      <NavLink
                        href="#"
                        onClick={e => {
                          e.preventDefault();
                          setActive("8");
                        }}
                      >
                        <i className={active === "8" ? "fa fa-angle-right pr-15" : ""}></i>
                          Smart Living
                         </NavLink>
                    </NavItem>
                    <NavItem className={active === "9" ? "active" : ""}>
                      <NavLink
                        href="#"
                        onClick={e => {
                          e.preventDefault();
                          setActive("9");
                        }}
                      >
                        <i className={active === "9" ? "fa fa-angle-right pr-15" : ""}></i>
                          Others
                         </NavLink>
                    </NavItem> */}
                  </ul>
                </div>
              </div>
              <div
                style={{
                  backgroundImage: "url(" + require("assets/img/bg-gray-additional.jpg") + ")",
                  backgroundRepeat: "round"
                }}>
                <Container>
                  <Row>
                    <Col md="12">
                      <p className="title-0 text-center text-white">
                        Have a Question?
                      </p>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <Input
                          className="form-control bg-white"
                          type="text"
                          placeholder="Your Name*"
                          maxLength={20}
                          value={formData.ptc || ""}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              ptc: e.currentTarget.value || "",
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        <Input
                          className="form-control bg-white"
                          type="text"
                          placeholder="E-mail*"
                          maxLength={50}
                          value={formData.ptcemail || ""}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              ptcemail: e.currentTarget.value || "",
                            });
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12">
                      <FormGroup>
                        {/* <Input type="textarea" className="form-control bg-white" placeholder="Message" /> */}

                        <Input
                          type="textarea"
                          className="form-control bg-white"
                          placeholder="Message"
                          maxLength={250}
                          value={formData.message || ""}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              message: e.currentTarget.value || "",
                            });
                          }}
                        />

                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="12" className="text-center">
                      <FormGroup>
                        <Button className="btn btn-round btn-info"
                          onClick={(e) => {
                            e.preventDefault();
                            FnContactUs(e);
                          }}
                        >SEND QUESTION
                        </Button>
                      </FormGroup>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
});