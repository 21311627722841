import React, { useContext } from "react";
import { Link } from 'react-router-dom';
import { GlobalContext } from '../../App.js';
function BookNow() {
  const globalContext = useContext(GlobalContext);
  return (
    <>
      {
        globalContext.loaderState.isLoading === false &&
        <div className="ml-auto mr-auto text-center"
          style={{
            backgroundColor: "#4bb5ff"
          }}
        >
          <div className="card-title bookNow-title">
            <b
              style={{
                color: "#ffffff"
              }}
            >Book a Home Service with us in 60 seconds</b>
            <Link to="/corporate-enquiry" className="btn-round btn btn-default btn-book-now">
              <i className="fas fa-bell pr-10"></i>
              Book Now</Link>
          </div>
        </div>
      }
    </>
  );
}

export default BookNow;
