

export const locationInitialState = {
    isLoading: false,
    LocationData: [],
    QuestionData: [],
    QuestionaireData: {
        locationid: "",
        locationname: "",
        fulladdr: "",
        housetype: "",
        area: "",
        bedroomqty: "0",
        bathroomqty: "0",
        addr1: "",
        addr2: "",
        addr3: "",
        unitno: "",
        postcode: "",
        ptc: "",
        specialinstruction: "",
    }
}

export const locationReducer = (state, action) => {
    switch (action.type) {
        case 'FIELD': {
            return {
                ...state,
                [action.field]: action.value
            }
        }
        case 'DYNAMIC_FIELD': {
            // locationInitialState.newPropertyData[action.field] = action.value
            return {
                ...state,
                QuestionaireData: { ...state.QuestionaireData, [action.field]: action.value }
            }
        }
        case 'LOCATION_START': {
            return {
                isLoading: false,
                LocationData: [],
                QuestionaireData: locationInitialState.QuestionaireData
            }
        }
        case 'QUESTION_START': {
            return {
                ...state,
                isLoading: false,
                LocationData: [],
            }
        }
        case 'LOCATION_SUCCESS': {
            const sc = {
                ...state,
                isLoading: false,
                LocationData: action.payload,
            };
            //console.log(sc)
            return sc;
        }
        case 'QUESTION_SUCCESS': {
            const sc = {
                ...state,
                isLoading: false,
                QuestionData: action.payload,
            };
            // console.log(sc)
            return sc;
        }
        case 'ERROR': {
            return {
                ...state,
                isLoading: false,
                LocationData: [],
                QuestionData: []
            }
        }
    }
    return state;
}
export const AssessmentInitialState = {
    isLoading: false,
    ServiceRequested: '',
    reqdate: '',
    reqtimefrom: '',
    reqtimeto: '',
    shipaddr: '',
    shipemail: '',
    shipname: '',
    shipphone: ''
}

export const AssessmentReducer = (state, action) => {
    switch (action.type) {
        case 'FIELD': {
            return {
                ...state,
                [action.field]: action.value
            }
        }
        case 'START_LOADING': {
            return {
                ...state,
                isLoading: true,
            }
        }
        case 'SUBMIT': {
            return {
                ...state,
                isLoading: true,
                ServiceRequested: '',
                reqdate: '',
                reqtimefrom: '',
                reqtimeto: '',
                shipaddr: '',
                shipemail: '',
                shipname: '',
                shipphone: ''
            }
        }
        case 'SUCCESS': {
            const sc = {
                ...state,
                isLoading: false,
                ServiceRequested: '',
                reqdate: '',
                reqtimefrom: '',
                reqtimeto: '',
                shipaddr: '',
                shipemail: '',
                shipname: '',
                shipphone: ''
            };
            console.log(sc)
            return sc;
        }
        case 'ERROR': {
            return {
                ...state,
                isLoading: false,
                ServiceRequested: '',
                reqdate: '',
                reqtimefrom: '',
                reqtimeto: '',
                shipaddr: '',
                shipemail: '',
                shipname: '',
                shipphone: ''
            }
        }
    }
    return state;



}


export const PackageListingInitialState = {
    bestMatched: [],
    header: [],
    previouslyBooked: [],
    popular: [],
    other: [],
    products: [],
    showother: false,
}

export const PackageListingReducer = (state, action) => {
    switch (action.type) {
        case 'SETDATA': {
            return {
                ...state,
                [action.key]: action.data
            }
        }
        case 'FEILD': {
            return {
                ...state,
                [action.field]: action.value
            }
        }
        default:
            return {
                ...state
            }
    }
}



export const ServiceBookingInitialState = {
    modal: false,
    visibleComponentType: '',
    visibleModalContentType: '',
    PackageDetails: {},
    modalSize: 'xl',
    callback: null
    //cartList: []
}

export const ServiceBookingReducer = (state, action) => {
    // console.log('action.type:' + action.type);
    switch (action.type) {
        case 'BOOKING_INFORMATION': {
            const abc = {
                ...state,
                modal: false,
                visibleComponentType: 'BOOKING_INFORMATION',
                visibleModalContentType: '',
                //PackageDetails: action.payload
            };
            // console.log(abc);
            return abc;
        }
        case 'FINALIZE_BOOKING': {

            const abc2 = {
                ...state,
                modal: false,
                visibleComponentType: 'FINALIZE_BOOKING',
                visibleModalContentType: '',
            };
            // console.log(abc2);
            return abc2;
        }
        case 'CHECKOUT_PAYMENT':
            return {
                ...state,
                modal: false,
                visibleComponentType: 'CHECKOUT_PAYMENT',
                visibleModalContentType: '',
                //cartList: action.payload
            }
        case 'DATE_TIME':
            return {
                ...state,
                modal: false,
                visibleComponentType: 'DATE_TIME',
                visibleModalContentType: '',
                //PackageDetails: action.payload
            }
        case 'PACKAGE_DETAILS':
            return {
                ...state,
                modal: true,
                modalSize: 'xl',
                visibleModalContentType: 'PACKAGE_DETAILS',
                PackageDetails: action.payload,
                callback: action.callback
            }
        case 'PACKAGE_ADD_ONS':
            return {
                ...state,
                modal: true,
                modalSize: 'xl',
                visibleModalContentType: 'PACKAGE_ADD_ONS',
                PackageDetails: action.payload,
                callback: action.callback
            }
        case 'MLT_PACKAGE_ADD_ONS':
            return {
                ...state,
                modal: true,
                modalSize: 'lg',
                visibleModalContentType: 'MLT_PACKAGE_ADD_ONS',
                PackageDetails: action.payload,
                callback: action.callback
            }
        case 'CLOSE_MODAL':
            return {
                ...state,
                modal: false,
                visibleModalContentType: '',
            }
        // default:
        //     return {
        //         ...state,
        //         modal: false,
        //         visibleComponentType: '',
        //     }
    }
    return state;

}



export const BookingInitialState = {
    timeslots: [],
    PackageDetails: {},
    cartList: [],
    LocationData: [],
    finalAmount: 0,
    discAmt: 0,
    shippingAmt: 0,
    promoCode: "",
    serviceFee: 0,
    subtotal: 0,
    total: 0,
}

export const BookingReducer = (state, action) => {
    switch (action.type) {
        case 'FIELD': {
            return {
                ...state,
                // [action.field]: action.value
                PackageDetails: { ...state.PackageDetails, [action.field]: action.value }
            }
        }

        //work for active booking
        case 'STARDATE_FIELD': {
            return {
                ...state,
                // [action.field]: action.value
                PackageDetails: { ...state.PackageDetails, [action.field]: action.value, startdate2: '' }
            }
        }

        // case 'STARDATE_FIELD': {
        //     return {
        //         ...state,
        //         // [action.field]: action.value
        //         PackageDetails: { ...state.PackageDetails, [action.field]: action.value, startdate2: '' }
        //     }
        // }

        case 'TIME_SLOT_SUCCESS': {
            return {
                ...state,
                timeslots: action.payload
            }
        }

        case 'NUMBER': {
            return {
                ...state,
                PackageDetails: action.value.length <= action.digit ?
                    { ...state.PackageDetails, [action.field]: action.value } :
                    state.PackageDetails
            }
        }
        case 'SET_DATA': {
            return {
                ...state,
                PackageDetails: action.payload
            }
        }
        case 'LOCATION_SUCCESS': {
            return {
                ...state,
                LocationData: action.payload,
            }
        }
        // case 'SET_DATA': {
        //     return {
        //         ...state,
        //         PackageDetails: action.payload
        //     }
        // }

        case 'UPDATE_QTY': {

            // const optamt = state.cartList.map((item) =>
            //     (item.guid === action.guid && parseInt(action.value) > 0 && action.value.length < 3) ?
            //         {
            //             ...item,
            //             [action.field]: action.value,
            //             discamt: 0,
            //             disctype: '',
            //             promocode: ''
            //         }
            //         : item
            // ),

            return {
                ...state,
                cartList: state.cartList.map((item) =>
                    (item.guid === action.guid && parseInt(action.value) > 0 && action.value.length < 3) ?
                        {
                            ...item,
                            [action.field]: action.value,
                            discamt: 0,
                            disctype: '',
                            promocode: '',
                            addonList: item.addonList.map((item2) =>
                                (
                                    {
                                        ...item2,
                                        qty: parseInt(action.value),
                                        totalamt: parseInt(item2.price) * parseFloat(action.value)
                                    }
                                )
                            ),
                            optamt: item.addonList.reduce((tl, item) => {
                                return tl + (parseInt(item.price) * parseFloat(action.value))
                            }, 0)
                        }
                        : item
                ),
                finalAmount: state.cartList.reduce((tl, item) => {
                    return tl + (
                        (item.guid === action.guid) ?
                            ((parseInt(action.value) > 0 && action.value.length < 3) ?
                                (((parseFloat(item.baseamt || 0) + parseFloat(item.totalWkdamt || 0) + parseFloat(item.totalLocamt || 0) + parseFloat(item.optamt || 0)) * parseInt(action.value || 0)) - 0)
                                : (((parseFloat(item.baseamt || 0) + parseFloat(item.totalWkdamt || 0) + parseFloat(item.totalLocamt || 0) + parseFloat(item.optamt || 0)) * parseInt(item.qty || 0)) - parseFloat(item.discamt))
                            )
                            : (((parseFloat(item.baseamt || 0) + parseFloat(item.totalWkdamt || 0) + parseFloat(item.totalLocamt || 0) + parseFloat(item.optamt || 0)) * parseInt(item.qty || 0)) - parseFloat(item.discamt))

                        // ((parseInt(action.value) > 0 && action.value.length < 3) ?
                        //     ((((parseFloat(item.baseamt || 0) + parseFloat(item.totalWkdamt || 0) + parseFloat(item.totalLocamt || 0)) * parseInt(action.value || 0)) + parseFloat(item.optamt || 0)) - 0)
                        //     : ((((parseFloat(item.baseamt || 0) + parseFloat(item.totalWkdamt || 0) + parseFloat(item.totalLocamt || 0)) * parseInt(item.qty || 0)) + parseFloat(item.optamt || 0)) - parseFloat(item.discamt))
                        // )
                        // : ((((parseFloat(item.baseamt || 0) + parseFloat(item.totalWkdamt || 0) + parseFloat(item.totalLocamt || 0)) * parseInt(item.qty || 0)) + parseFloat(item.optamt || 0)) - parseFloat(item.discamt))
                    )
                }, 0)
            }
        }

        case 'APPLY_COUPON': {
            return {
                ...state,
                // cartList: state.cartList.map((item) =>
                //     (item.guid === action.guid) ?
                //         {
                //             ...item,
                //             [action.field]: action.value,
                //         }
                //         : item
                // )
                promoCode: action.promocode || '',

            }
        }
        case 'UPDATE_COUPON_AMOUNT': {
            return {
                ...state,
                cartList: state.cartList.map((item) =>
                    (item.guid === action.guid) ?
                        {
                            ...item,
                            discamt: action.discamt,
                            disctype: action.disctype,
                        }
                        : item
                ),
                finalAmount: state.cartList.reduce((tl, item) => {
                    return tl + ((item.guid === action.guid) ?
                        (((parseFloat(item.baseamt || 0) + parseFloat(item.totalWkdamt || 0) + parseFloat(item.totalLocamt || 0) + parseFloat(item.optamt || 0)) * parseInt(item.qty || 0)) - parseFloat(action.discamt))
                        : (((parseFloat(item.baseamt || 0) + parseFloat(item.totalWkdamt || 0) + parseFloat(item.totalLocamt || 0) + parseFloat(item.optamt || 0)) * parseInt(item.qty || 0)) - parseFloat(item.discamt))
                        // ((((parseFloat(item.baseamt || 0) + parseFloat(item.totalWkdamt || 0) + parseFloat(item.totalLocamt || 0)) * parseInt(item.qty || 0)) + parseFloat(item.optamt || 0)) - parseFloat(action.discamt))
                        // : ((((parseFloat(item.baseamt || 0) + parseFloat(item.totalWkdamt || 0) + parseFloat(item.totalLocamt || 0)) * parseInt(item.qty || 0)) + parseFloat(item.optamt || 0)) - parseFloat(item.discamt))
                    )
                }, 0)
            }
        }
        case 'REMOVE_COUPON': {
            return {
                ...state,
                // cartList: state.cartList.map((item) =>
                //     (item.guid === action.guid) ?
                //         {
                //             ...item,
                //             discamt: 0,
                //             disctype: '',
                //             promocode: ''
                //         }
                //         : item
                // ),
                // finalAmount: state.cartList.reduce((tl, item) => {
                //     return tl + ((item.guid === action.guid) ?
                //         (((parseFloat(item.baseamt || 0) + parseFloat(item.totalWkdamt || 0) + parseFloat(item.totalLocamt || 0) + parseFloat(item.optamt || 0)) * parseInt(item.qty || 0)))
                //         :
                //         (((parseFloat(item.baseamt || 0) + parseFloat(item.totalWkdamt || 0) + parseFloat(item.totalLocamt || 0) + parseFloat(item.optamt || 0)) * parseInt(item.qty || 0)) - parseFloat(item.discamt))
                //         // (((parseFloat(item.baseamt || 0) + parseFloat(item.totalWkdamt || 0) + parseFloat(item.totalLocamt || 0)) * parseInt(item.qty || 0)) + parseFloat(item.optamt || 0))
                //         // :
                //         // ((((parseFloat(item.baseamt || 0) + parseFloat(item.totalWkdamt || 0) + parseFloat(item.totalLocamt || 0)) * parseInt(item.qty || 0)) + parseFloat(item.optamt || 0)) - parseFloat(item.discamt))
                //     )
                // }, 0)
                discAmt: 0,
                promoCode: '',
                total: parseFloat(state.total || 0) + parseFloat(state.discAmt || 0)
            }
        }

        case 'REMOVE_PRODUCT': {
            return {
                ...state,
                cartList: state.cartList.filter((item) =>
                    item.guid !== action.guid && item
                ),
                subtotal: state.cartList.reduce((tl, item) => {
                    return tl + (
                        item.guid === action.guid ? 0 : (((parseFloat(item.baseamt || 0) + parseFloat(item.totalWkdamt || 0) + parseFloat(item.totalLocamt || 0) + parseFloat(item.optamt || 0)) * parseInt(item.qty || 0)) - parseFloat(item.discamt))
                        //item.guid === action.guid ? 0 : ((((parseFloat(item.baseamt || 0) + parseFloat(item.totalWkdamt || 0) + parseFloat(item.totalLocamt || 0)) * parseInt(item.qty || 0)) + parseFloat(item.optamt || 0)) - parseFloat(item.discamt))
                    );
                }, 0),
            }
        }

        case 'REMOVE_ADD_ONS': {
            return {
                ...state,
                cartList: state.cartList.map((item) =>
                    (item.guid === action.pguid) ?
                        {
                            ...item,
                            discamt: 0,
                            disctype: '',
                            promocode: '',
                            optamt: (item.optamt - (parseFloat(action.price || 0) * parseFloat(action.qty || 0))),
                            addonList: item.addonList.filter((item2) =>
                                (item2.guid !== action.guid) && item2
                            )
                        }
                        : item
                ),
                subtotal: state.cartList.reduce((tl, item) => {
                    return tl + ((item.guid === action.pguid) ?
                        // (((parseFloat(item.baseamt || 0) + parseFloat(item.totalWkdamt || 0) + parseFloat(item.totalLocamt || 0) + parseFloat(action.optamt || 0)) * parseInt(item.qty || 0)) - parseFloat(item.discamt))
                        // : (((parseFloat(item.baseamt || 0) + parseFloat(item.totalWkdamt || 0) + parseFloat(item.totalLocamt || 0) + parseFloat(item.optamt || 0)) * parseInt(item.qty || 0)) - parseFloat(item.discamt))
                        // ((((parseFloat(item.baseamt || 0) + parseFloat(item.totalWkdamt || 0) + parseFloat(item.totalLocamt || 0)) * parseInt(item.qty || 0)) + parseFloat(item.optamt || 0)) - 0) - (parseFloat(action.price) * parseFloat(action.qty))
                        // : ((((parseFloat(item.baseamt || 0) + parseFloat(item.totalWkdamt || 0) + parseFloat(item.totalLocamt || 0)) * parseInt(item.qty || 0)) + parseFloat(item.optamt || 0)) - parseFloat(item.discamt))

                        ((((parseFloat(item.baseamt || 0) + parseFloat(item.totalWkdamt || 0) + parseFloat(item.totalLocamt || 0)) * parseInt(item.qty || 0)) + (parseFloat(item.optamt || 0) - (parseFloat(action.price || 0) * parseFloat(action.qty || 0)))) - parseFloat(action.discamt || 0))
                        : ((((parseFloat(item.baseamt || 0) + parseFloat(item.totalWkdamt || 0) + parseFloat(item.totalLocamt || 0)) * parseInt(item.qty || 0)) + parseFloat(item.optamt || 0)) - parseFloat(item.discamt || 0))
                    )
                }, 0)
            }
        }

        case 'ADD_ONS': {
            return {
                ...state,
                cartList: state.cartList.map((item) =>
                    (item.guid === action.guid) ?
                        {
                            ...item,
                            addon: action.addon,
                            optamt: action.optamt,
                        }
                        : item
                ),
                subtotal: state.cartList.reduce((tl, item) => {
                    return tl + ((item.guid === action.guid) ?
                        (((parseFloat(item.baseamt || 0) + parseFloat(item.totalWkdamt || 0) + parseFloat(item.totalLocamt || 0) + parseFloat(action.optamt || 0)) * parseInt(item.qty || 0)) - parseFloat(item.discamt))
                        : (((parseFloat(item.baseamt || 0) + parseFloat(item.totalWkdamt || 0) + parseFloat(item.totalLocamt || 0) + parseFloat(item.optamt || 0)) * parseInt(item.qty || 0)) - parseFloat(item.discamt))

                        // ((((parseFloat(item.baseamt || 0) + parseFloat(item.totalWkdamt || 0) + parseFloat(item.totalLocamt || 0)) * parseInt(item.qty || 0)) + parseFloat(action.optamt || 0)) - parseFloat(item.discamt))
                        //: ((((parseFloat(item.baseamt || 0) + parseFloat(item.totalWkdamt || 0) + parseFloat(item.totalLocamt || 0)) * parseInt(item.qty || 0)) + parseFloat(item.optamt || 0)) - parseFloat(item.discamt))
                    )
                }, 0)
            }
        }
        // case 'FINAL_AMOUNT': {
        //     return {
        //         ...state,
        //         finalAmount: state.cartList.reduce((fa, item) => {
        //             return fa + (((parseFloat(item.baseamt || 0) + parseFloat(item.totalWkdamt || 0) + parseFloat(item.totalLocamt || 0) + parseFloat(item.optamt || 0)) * parseInt(item.qty || 0)) - parseFloat(item.discamt))
        //         }, 0)
        //     }
        // }
        case 'CART_SUCCESS': {
            return {
                ...state,
                cartList: action.payload.details,
                discAmt: parseFloat(action.payload.discAmt || 0),
                promoCode: action.payload.promoCode || '',
                serviceFee: parseFloat(action.payload.serviceFee || 0),
                subtotal: parseFloat(action.payload.subtotal || 0),
                total: parseFloat(action.payload.total || 0),
                shippingAmt: parseFloat(action.payload.shippingAmt || 0)
                // finalAmount: action.payload.reduce((fa, item) => {
                //     return fa + (((parseFloat(item.baseamt || 0) + parseFloat(item.totalWkdamt || 0) + parseFloat(item.totalLocamt || 0) + parseFloat(item.optamt || 0)) * parseInt(item.qty || 0)) - parseFloat(item.discamt))
                //     //  return fa + ((((parseFloat(item.baseamt || 0) + parseFloat(item.totalWkdamt || 0) + parseFloat(item.totalLocamt || 0)) * parseInt(item.qty || 0)) + parseFloat(item.optamt || 0)) - parseFloat(item.discamt))
                // }, 0)
            }
        }

        default: {
            return {
                ...state
            }
        }
    }
}





// //pick date time
// export const PDTInitialState = {
//     timeslots: [],
//     PackageDetails: {},
// }

// export const PDTReducer = (state, action) => {
//     switch (action.type) {
//         case 'FIELD': {
//             return {
//                 ...state,
//                 // [action.field]: action.value
//                 PackageDetails: { ...state.PackageDetails, [action.field]: action.value }
//             }
//         }
//         case 'SUCCESS': {
//             return {
//                 ...state,
//                 timeslots: action.payload
//             }
//         }
//         case 'SET_DATA': {
//             return {
//                 ...state,
//                 PackageDetails: action.payload
//             }
//         }
//         case 'ERROR': {
//             return {
//                 ...state,
//                 timeslots: []
//             }
//         }
//     }
//     return state;



// }

// //booking information
// export const BIInitialState = {
//     LocationData: [],
//     PackageDetails: {},
// }

// export const BIReducer = (state, action) => {
//     switch (action.type) {
//         case 'FIELD': {
//             return {
//                 ...state,
//                 // [action.field]: action.value
//                 PackageDetails: { ...state.PackageDetails, [action.field]: action.value }
//             }
//         }
//         case 'NUMBER': {
//             return {
//                 ...state,
//                 PackageDetails: action.value.length <= action.digit ?
//                     { ...state.PackageDetails, [action.field]: action.value } :
//                     state.PackageDetails
//             }
//         }
//         case 'SET_DATA': {
//             return {
//                 ...state,
//                 PackageDetails: action.payload
//             }
//         }
//         case 'SUCCESS': {
//             return {
//                 ...state,
//                 LocationData: action.payload,
//             }
//         }
//         case 'ERROR': {
//             return {
//                 ...state,
//                 LocationData: [],
//             }
//         }
//     }
//     return state;
// }



// //finalize booking 
// export const FBInitialState = {
//     isLoading: false,
//     finalAmount: 0,
//     cartList: [],
// }

// export const FBReducer = (state, action) => {
//     switch (action.type) {
//         case 'FIELD': {
//             return {
//                 ...state,
//                 [action.field]: action.value
//             }
//         }
//         case 'UPDATE_QTY': {
//             return {
//                 ...state,
//                 cartList: state.cartList.map((item) =>
//                     (item.guid === action.guid && action.value > 0 && action.value.length < 3) ?
//                         {
//                             ...item,
//                             [action.field]: action.value,
//                         }
//                         : item
//                 ),
//                 finalAmount: state.cartList.reduce((tl, item) => {
//                     return tl + (
//                         (item.guid === action.guid) ?
//                             ((action.value > 0 && action.value.length < 3) ?
//                                 (((parseFloat(item.baseamt || 0) + parseFloat(item.totalWkdamt || 0) + parseFloat(item.totalLocamt || 0) + parseFloat(item.optamt || 0)) * parseInt(action.value || 0)) - parseFloat(item.discamt))
//                                 : (((parseFloat(item.baseamt || 0) + parseFloat(item.totalWkdamt || 0) + parseFloat(item.totalLocamt || 0) + parseFloat(item.optamt || 0)) * parseInt(item.qty || 0)) - parseFloat(item.discamt))
//                             )
//                             : (((parseFloat(item.baseamt || 0) + parseFloat(item.totalWkdamt || 0) + parseFloat(item.totalLocamt || 0) + parseFloat(item.optamt || 0)) * parseInt(item.qty || 0)) - parseFloat(item.discamt))
//                     )
//                 }, 0)
//             }
//         }

//         case 'APPLY_COUPON': {
//             return {
//                 ...state,
//                 cartList: state.cartList.map((item) =>
//                     (item.guid === action.guid) ?
//                         {
//                             ...item,
//                             [action.field]: action.value,
//                         }
//                         : item
//                 )
//             }
//         }
//         case 'UPDATE_COUPON_AMOUNT': {
//             return {
//                 ...state,
//                 cartList: state.cartList.map((item) =>
//                     (item.guid === action.guid) ?
//                         {
//                             ...item,
//                             ['discamt']: action.discamt,
//                             ['disctype']: action.disctype,
//                         }
//                         : item
//                 ),
//                 finalAmount: state.cartList.reduce((tl, item) => {
//                     return tl + ((item.guid === action.guid) ?
//                         (((parseFloat(item.baseamt || 0) + parseFloat(item.totalWkdamt || 0) + parseFloat(item.totalLocamt || 0) + parseFloat(item.optamt || 0)) * parseInt(item.qty || 0)) - parseFloat(action.discamt))
//                         : (((parseFloat(item.baseamt || 0) + parseFloat(item.totalWkdamt || 0) + parseFloat(item.totalLocamt || 0) + parseFloat(item.optamt || 0)) * parseInt(item.qty || 0)) - parseFloat(item.discamt))
//                     )
//                 }, 0)
//             }
//         }
//         case 'REMOVE_COUPON': {
//             return {
//                 ...state,
//                 cartList: state.cartList.map((item) =>
//                     (item.guid === action.guid) ?
//                         {
//                             ...item,
//                             ['discamt']: 0,
//                             ['disctype']: '',
//                             ['promocode']: ''
//                         }
//                         : item
//                 ),
//                 finalAmount: state.cartList.reduce((tl, item) => {
//                     return tl + ((item.guid === action.guid) ?
//                         (((parseFloat(item.baseamt || 0) + parseFloat(item.totalWkdamt || 0) + parseFloat(item.totalLocamt || 0) + parseFloat(item.optamt || 0)) * parseInt(item.qty || 0)))
//                         :
//                         (((parseFloat(item.baseamt || 0) + parseFloat(item.totalWkdamt || 0) + parseFloat(item.totalLocamt || 0) + parseFloat(item.optamt || 0)) * parseInt(item.qty || 0)) - parseFloat(item.discamt))
//                     )
//                 }, 0)
//             }
//         }

//         case 'REMOVE_PRODUCT': {
//             return {
//                 ...state,
//                 cartList: state.cartList.filter((item) =>
//                     item.guid !== action.guid && item
//                 ),
//                 finalAmount: state.cartList.reduce((tl, item) => {
//                     return tl + (
//                         item.guid === action.guid ? 0 : (((parseFloat(item.baseamt || 0) + parseFloat(item.totalWkdamt || 0) + parseFloat(item.totalLocamt || 0) + parseFloat(item.optamt || 0)) * parseInt(item.qty || 0)) - parseFloat(item.discamt))
//                     );
//                 }, 0),
//             }
//         }

//         case 'ADD_ONS': {
//             return {
//                 ...state,
//                 cartList: state.cartList.map((item) =>
//                     (item.guid === action.guid) ?
//                         {
//                             ...item,
//                             ['addon']: action.addon,
//                             ['optamt']: action.optamt,
//                         }
//                         : item
//                 ),
//                 finalAmount: state.cartList.reduce((tl, item) => {
//                     return tl + ((item.guid === action.guid) ?
//                         (((parseFloat(item.baseamt || 0) + parseFloat(item.totalWkdamt || 0) + parseFloat(item.totalLocamt || 0) + parseFloat(action.optamt || 0)) * parseInt(item.qty || 0)) - parseFloat(item.discamt))
//                         : (((parseFloat(item.baseamt || 0) + parseFloat(item.totalWkdamt || 0) + parseFloat(item.totalLocamt || 0) + parseFloat(item.optamt || 0)) * parseInt(item.qty || 0)) - parseFloat(item.discamt))
//                     )
//                 }, 0)
//             }
//         }
//         case 'FINAL_AMOUNT': {
//             return {
//                 ...state,
//                 finalAmount: state.cartList.reduce((fa, item) => {
//                     return fa + (((parseFloat(item.baseamt || 0) + parseFloat(item.totalWkdamt || 0) + parseFloat(item.totalLocamt || 0) + parseFloat(item.optamt || 0)) * parseInt(item.qty || 0)) - parseFloat(item.discamt))
//                 }, 0)
//             }
//         }
//         case 'SUCCESS': {
//             return {
//                 ...state,
//                 cartList: action.payload,
//             }
//         }
//         case 'ERROR': {
//             return {
//                 ...state,
//                 cartList: [],
//             }
//         }
//     }
//     return state;
// }


//Checkout Page
export const COInitialState = {
    cartList: [],
    refnum: '',
    totalamt: 0,
    totalLocamt: 0,
    optamt: 0,
    discamt: 0,
    disctype: '',
    finalAmount: 0,
    firstname: '',
    lastname: '',
    phoneNumber: '',
    email: '',
    postalCode: '',
    addr1: '',
    addr2: '',
    addr3: '',
    creditredeem: '',
    availableCredit: 0,
    redeemamt: 0,
    cardtype: '',
    nameoncard: '',
    acnumber: '',
    exmm: '',
    exyyyy: '',
    otp: '',
    isPaymentStart: false,
    error: null,
    processing: false,
    transationId: '',
    cardComplete: false,
    unitno: '',

    discAmt: 0,
    promoCode: "",
    serviceFee: 0,
    subtotal: 0,
    shippingAmt: 0,
    total: 0,
    paymentType: "card",
    title: '',

    twoc2pCardNumber: "",
    twoc2pExpiry: "",
    twoc2pCvc: "",
    
    uktakepayCardNumber: "",
    uktakepayExpiry: "",
    uktakepayCvc: ""

}

export const COReducer = (state, action) => {
    switch (action.type) {
        case 'FIELD': {
            return {
                ...state,
                [action.field]: action.value
            }
        }
        case 'NUMBER': {
            return {
                ...state,
                [action.field]: action.value.length <= action.digit ? action.value : state[action.field],
            }
        }
        case 'REDEEM_CREDIT': {
            return {
                ...state,
                // creditredeem: (parseFloat(action.value || 0)) >= 0 && (parseFloat(action.value || 0) <= parseFloat(state.availableCredit || 0) &&
                //     parseFloat(action.value || 0) <= parseFloat(state.finalAmount || 0))
                //     ? action.value : state.creditredeem,


                creditredeem: (parseFloat(action.value || 0)) >= 0 && (parseFloat(action.value || 0) <= parseFloat(state.availableCredit || 0) &&
                    parseFloat(action.value || 0) <= parseFloat(state.total || 0))
                    ? action.value : state.creditredeem,
            }
        }
        case 'FINAL_AMOUNT': {
            return {
                ...state,
                finalAmount: state.cartList.reduce((fa, item) => {
                    // return fa + ((parseFloat(item.totalamt, 0) + parseFloat(item.totalLocamt, 0) + parseFloat(item.optamt, 0)) - (parseFloat(item.discamt, 0)))
                    return fa + (parseFloat(item.totalamt, 0))
                }, 0),
            }
        }

        case 'SET_CART_ITEMS': {
            return {
                ...state,
                error: null,
                cartList: action.cartList,
                // totalamt: action.payload.reduce((st, item) => {
                //     // return st + ((((parseFloat(item.baseamt || 0) + parseFloat(item.totalWkdamt || 0) + parseFloat(item.totalLocamt || 0) + parseFloat(item.optamt || 0)) * parseInt(item.qty || 0)) - parseFloat(item.discamt || 0)));
                //     return st + (((((parseFloat(item.baseamt || 0) + parseFloat(item.totalWkdamt || 0) + parseFloat(item.totalLocamt || 0)) * parseInt(item.qty || 0)) + parseFloat(item.optamt || 0)) - parseFloat(item.discamt || 0)));
                // }, 0),
                // totalLocamt: action.payload.reduce((tf, item) => {
                //     return tf + ((parseFloat(item.totalLocamt || 0)) * parseInt(item.qty || 1));
                // }, 0),
                // // optamt: action.payload.reduce((oa, item) => {
                // //     return oa + ((parseFloat(item.optamt || 0)) * parseInt(item.qty || 1));
                // // }, 0),

                optamt: action.cartList.reduce((oa, item) => {
                    return oa + ((parseFloat(item.optamt || 0)));
                }, 0),
                // discamt: action.payload.reduce((d, item) => {
                //     return d + (parseFloat(item.discamt));
                // }, 0),
                // finalAmount: action.payload.reduce((fa, item) => {
                //     // return fa + ((parseFloat(item.totalamt, 0) + parseFloat(item.totalLocamt, 0) + parseFloat(item.optamt, 0)) - (parseFloat(item.discamt, 0)))
                //     return fa + (((((parseFloat(item.baseamt || 0) + parseFloat(item.totalWkdamt || 0) + parseFloat(item.totalLocamt || 0)) * parseInt(item.qty || 0)) + parseFloat(item.optamt || 0)) - parseFloat(item.discamt || 0)));
                // }, 0),
                refnum: action.cartList.slice(-1).pop().refnum || '',
                //disctype: action.payload.slice(-1).pop().disctype || '',

                discAmt: parseFloat(action.discAmt || 0),
                promoCode: action.promoCode || '',
                serviceFee: parseFloat(action.serviceFee || 0),
                subtotal: parseFloat(action.subtotal || 0),
                total: parseFloat(action.total || 0),
                shippingAmt: parseFloat(action.shippingAmt || 0)

            }
        }
        case 'SET_ADDRESS':
            // console.log(state)
            return {
                ...state,
                postalCode: action.value ? state.cartList.slice(-1).pop().shippostcode : '',
                addr1: action.value ? state.cartList.slice(-1).pop().shipaddr1 : '',
                addr2: action.value ? state.cartList.slice(-1).pop().shipaddr2 : '',
                addr3: action.value ? state.cartList.slice(-1).pop().shipaddr3 : '',
                unitno: action.value ? state.cartList.slice(-1).pop().shipunitno : '',
            }
        case 'SUCCESS': {
            return {
                ...state,
                cartList: action.payload,
            }
        }

        case 'IS_CARD_COMPLETE': {
            return {
                ...state,
                cardComplete: action.status,
                error: action.error
            }
        }
        case 'PAYMENT_START': {
            return {
                ...state,
                error: null,
                processing: true
            }
        }
        case 'PAYMENT_ERROR': {
            return {
                ...state,
                error: action.payload,
                processing: false
            }
        }
        case 'PAYMENT_SUCCESS': {
            return {
                ...state,
                processing: false,
                transationId: action.payload
            }
        }
        default: {
            return {
                ...state,
                cartList: [],
                totalamt: 0,
                totalLocamt: 0,
                optamt: 0,
                discamt: 0,
                finalAmount: 0,
                firstname: '',
                lastname: '',
                phoneNumber: '',
                email: '',
                postalCode: '',
                addr1: '',
                addr2: '',
                addr3: '',
                availableCredit: 0,
                creditredeem: 0,
                error: null,
                unitno: ''
            }
        }
    }
}

//Add Ons
export const AddOnsInitialState = {
    addOnsList: [],
    addOnsGroupList: [],
    selectedAddOnsList: [],
}

export const AddOnsReducer = (state, action) => {
    switch (action.type) {
        case 'FIELD': {
            return {
                ...state,
                [action.field]: action.value
            }
        }

        case 'SELECT_ITEM': {
            return {
                ...state,
                // selectedAddOnsList: [...state.selectedAddOnsList,
                // {
                //     choicetype: action.payload.choicetype,
                //     groupdesc: action.payload.groupdesc,
                //     groupnum: action.payload.groupnum,
                //     maxchoice: parseInt(action.payload.maxchoice || 0),
                //     minchoice: parseInt(action.payload.minchoice || 0),
                //     optiondesc: action.payload.optiondesc,
                //     optionnum: action.payload.optionnum,
                //     price: parseFloat(action.payload.price || 0),
                //     selected: true
                // }]

                addOnsList: state.addOnsList.map((item) =>
                    (item.groupnum === action.payload.groupnum && item.choicetype === action.payload.choicetype && item.optionnum === action.payload.optionnum) ?
                        {
                            ...item,
                            selected: true,
                            qty: parseInt(action.payload.qty || 1)
                        }
                        : item
                ),
            }
        }

        case 'UNSELECT_ITEM': {
            return {
                ...state,

                // addOnsList: state.addOnsList.map((item) =>
                //     !(item.groupnum === action.groupnum && item.choicetype === action.choicetype && item.optionnum === action.optionnum) &&
                //     state.selectedAddOnsList
                // )
                // ,
                addOnsList: state.addOnsList.map((item) =>
                    (item.groupnum === action.groupnum && item.choicetype === action.choicetype && item.optionnum === action.optionnum) ?
                        {
                            ...item,
                            selected: false,
                            qty: 0
                        }
                        : item
                ),
            }
        }

        //use to bind items in group, group name list
        case 'SET_GROUP': {
            return {
                ...state,
                addOnsGroupList: action.payload
            }
        }
        case 'SUCCESS': {
            return {
                ...state,
                addOnsList: action.payload,
            }
        }
        default: {
            return {
                ...state,
                // addOnsList: [],
                // selectedAddOnsList: [],
                // addOnsGroupList: []
            }
        }
    }
}