import React, { useContext, useEffect, useReducer } from 'react';
import { Button, ModalBody, ModalHeader, Form, Row, Col, Container, Input } from 'reactstrap';
import { GlobalContext } from '../../App.js';
import { api } from "../../constant";
import Loader from './Loader';
import { ForgotPassInitialState, ForgotPassReducer } from '../../reducer/LoginReducer';
import { PostAPI } from '../../API/APICalling';

export default function ModalForgotPassword(props) {
    const globalContext = useContext(GlobalContext);
    const [state, dispatch] = useReducer(ForgotPassReducer, ForgotPassInitialState);
    const { isLoading, otpSent, email, password, confirmPassword, otp } = state;

    useEffect(() => {
        document.querySelector('.modal').scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    }, []);


    const FnSentOTP = async (e) => {
        if (!email) {
            globalContext.notifications('error', 'Email id required to reset password.');
            return false
        }
        globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
        var result = await PostAPI(`user/validate?email=${email}`);
        if (result) {
            globalContext.loaderDispatch({ type: 'STOP_LOADING' });
            if (result.error) {
                globalContext.notifications('error', result.error);
            }
            else {
                dispatch({ type: 'VALIDATE_USER_SUCCESS', otp: result.success, email: email });
                globalContext.notifications('success', 'Please check your email.');
            }
        }
    }


    // const FnSentOTP = async e => {
    //     // console.log(e);
    //     if (!email) {
    //         globalContext.notifications('error', 'Email id required to reset password.');
    //         return false
    //     }
    //     dispatch({ type: 'START' });
    //     API.post(`user/validate?email=${email}`)
    //         .then(res => {
    //             const response = res.data;
    //             if (response != null) {
    //                 if (response.meta.responseCode === 'S') {
    //                     dispatch({ type: 'VALIDATE_USER_SUCCESS', otp: response.meta.message, email: email });
    //                     globalContext.notifications('success', 'Please check your email.');
    //                 }
    //                 else {
    //                     dispatch({ type: 'ERROR' });
    //                     globalContext.notifications('error', response.meta.message);
    //                 }
    //             }
    //             else {
    //                 dispatch({ type: 'ERROR' });
    //                 globalContext.notifications('error', 'Oops ! Something went wrong.');
    //             }
    //         })
    //         .catch(error => {
    //             dispatch({ type: 'ERROR' });
    //             globalContext.notifications('error', 'Oops ! Something went wrong.');
    //             console.log(error);
    //         });
    // };


    const FnResetPassword = async (e) => {
        if (!email) {
            globalContext.notifications('error', 'Email id required to reset password.');
            dispatch({ type: 'CLOSE' })
            return false
        } 
        if (!otp) {
            globalContext.notifications('error', 'OTP is required.');
            return false;
        }
        if (!password) {
            globalContext.notifications('error', 'Password is required.');
            return false;
        }
        if (!confirmPassword) {
            globalContext.notifications('error', 'Confirm password is required.');
            return false;
        }
        if (confirmPassword !== password) {
            globalContext.notifications('error', 'Password and confirm password must be same.');
            return false;
        }

        globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
        var result = await PostAPI(`user/resetpwd`,
            {
                email: email,
                encryptedNewPWD: confirmPassword,
                OTP: otp,
            });
        if (result) {
            globalContext.loaderDispatch({ type: 'STOP_LOADING' });
            if (result.error) {
                globalContext.notifications('error', result.error);
            }
            else {
                dispatch({ type: 'UPDATE_PASS_SUCCESS' });
                globalContext.notifications('success', 'Password reset successfully.');
                globalContext.headerDispatch({ type: 'HEADERLOGIN' });
            }
        }
    }
    // const FnResetPassword = async e => {
    //     //console.log(email);
    //     dispatch({ type: 'UPDATE_PASS_START' });
    //     API.post(`user/resetpwd`,
    //         {
    //             email: email,
    //             encryptedNewPWD: confirmPassword,
    //             OTP: otp,
    //         }).then(res => {
    //             const response = res.data;
    //             if (response != null) {
    //                 if (response.meta.responseCode === 'S') {
    //                     dispatch({ type: 'UPDATE_PASS_SUCCESS' });
    //                     globalContext.notifications('success', 'Password reset successfully.');
    //                     globalContext.headerDispatch({ type: 'HEADERLOGIN' });
    //                 }
    //                 else {
    //                     dispatch({ type: 'ERROR' });
    //                     globalContext.notifications('error', response.meta.message);
    //                 }
    //             }
    //             else {
    //                 dispatch({ type: 'ERROR' });
    //                 globalContext.notifications('error', 'Oops ! Something went wrong.');
    //             }
    //         }).catch(error => {
    //             dispatch({ type: 'ERROR' })
    //             globalContext.notifications('error', 'Oops ! Something went wrong.');
    //             console.log(error);
    //         });
    // };


    return (
        <>
            {/* {isLoading ? <Loader /> : null} */}
            <div className="otp-modal">
                <ModalHeader toggle={() => globalContext.headerDispatch({ type: 'CLOSE' })}>
                </ModalHeader>
                <ModalBody>
                    <Container>
                        <Row>
                            <Col md="7" className="ml-auto mr-auto ">
                                <Form>
                                    <div className="form-group text-center">
                                        <img
                                            alt="Whissh Image"
                                            src={require("../../assets/img/icon-login.png")}
                                            style={{ height: "50px" }}
                                        >
                                        </img>
                                    </div>
                                    <div className="form-group text-center">
                                        <b className="title-3">
                                            RESET PASSWORD
                                          </b>
                                    </div>
                                    <hr></hr>

                                    {otpSent ? (
                                        <div>
                                            <div className="form-group">
                                                <p className="text-center description">
                                                    Enter the 6 digit One Time Password (OTP) sent to your E-mail.
                                            </p>
                                            </div>
                                            <div className="form-group">
                                                <Input className="form-control text-center"
                                                    placeholder="OTP*"
                                                    type="text"
                                                    value={otp}
                                                    maxLength={6}
                                                    onChange={e =>
                                                        dispatch({
                                                            type: 'FIELD',
                                                            field: 'otp',
                                                            value: e.currentTarget.value
                                                        })
                                                    }
                                                ></Input>
                                            </div>
                                            <div className="form-group">
                                                <Input className="form-control"
                                                    placeholder="New Password*"
                                                    type="password"
                                                    value={password}
                                                    maxLength={20}
                                                    onChange={e =>
                                                        dispatch({
                                                            type: 'FIELD',
                                                            field: 'password',
                                                            value: e.currentTarget.value
                                                        })
                                                    }
                                                ></Input>
                                            </div>
                                            <div className="form-group">
                                                <Input className="form-control"
                                                    placeholder="Confirm New Password*"
                                                    type="password"
                                                    value={confirmPassword}
                                                    maxLength={20}
                                                    onChange={e =>
                                                        dispatch({
                                                            type: 'FIELD',
                                                            field: 'confirmPassword',
                                                            value: e.currentTarget.value
                                                        })
                                                    }
                                                ></Input>
                                            </div>

                                            <div className="form-group text-center">
                                                <Button type="button" className="btn-round btn btn-info" onClick={() => FnResetPassword()}>
                                                    SUBMIT</Button>
                                                <Button type="button" className="btn-round btn btn-default" onClick={() => dispatch({ type: 'CLOSE' })}>
                                                    BACK</Button>
                                            </div>
                                        </div>

                                    ) :

                                        <div>
                                            <div className="form-group">
                                                <p className="text-center description">
                                                    Enter your E-mail Id to reset your password.
                                                 </p>
                                            </div>
                                            <div className="form-group">
                                                <Input className="form-control"
                                                    placeholder="E-mail*"
                                                    type="text"
                                                    value={email}
                                                    onChange={e =>
                                                        dispatch({
                                                            type: 'FIELD',
                                                            field: 'email',
                                                            value: e.currentTarget.value
                                                        })
                                                    }
                                                ></Input>
                                            </div>
                                            <div className="form-group text-center">
                                                <Button type="button" className="btn-round btn btn-info" onClick={() => FnSentOTP()}>
                                                    SUBMIT</Button>
                                                <Button type="button" className="btn-round btn btn-default" onClick={() => globalContext.headerDispatch({ type: 'LOGIN' })}>
                                                    CANCEL</Button>
                                            </div>
                                        </div>
                                    }

                                </Form>
                            </Col>
                        </Row>
                    </Container>
                </ModalBody>
            </div>

        </>
    );

}
