import React, { useEffect, useState, useContext } from "react";
import { Container, Row, Col, Button } from "reactstrap";
import { Accordion, AccordionItem, AccordionItemHeading, AccordionItemButton, AccordionItemPanel } from 'react-accessible-accordion';
// https://www.npmjs.com/package/react-accessible-accordion
import "../../assets/css/accordion.css";
import { GetAPI } from "../../API/APICalling";
import { GlobalContext } from '../../App.js';
import { Link } from "react-router-dom";

export default function FAQ() {
  const globalContext = useContext(GlobalContext);
  const [data, setData] = useState('');

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    const FnGetCMSContent = async () => {
      globalContext.loaderDispatch({ type: 'START_LOADING', payload: true });
      var result = await GetAPI(`cms/faq`);
      if (result) {
        globalContext.loaderDispatch({ type: 'STOP_LOADING' });
        if (result.error) {
          globalContext.notifications('error', result.error);
        }
        else if (result.data) {
          console.log(result.data)
          setData(result.data.content.body)
        }
      }
    }
    FnGetCMSContent();
  }, []);


  return (
    <>
      <div className="adjust-navbar-breadcrumb">
        <div className="breadcrumb-outer">
          <Container className="breadcrumb-body">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/" tag={Link}>
                  Home
                </Link>
              </li>
              <li className="breadcrumb-item active">                
                <i
                  className="fa fa-angle-right pr-5"
                  aria-hidden="true"
                ></i>
                Frequenty Asked Questions
              </li>
            </ol>
          </Container>
        </div>
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-left" md="10">
              <div>
                <h1 className=" big-heading text-center">
                  Frequenty Asked Questions
                </h1>
              </div>
              <hr className="hr-large"></hr>

              <div>
                <p className="description text-center pb-10">
                  Many of our customers have specifc questions about our
                  professional maid services. Here are jsut a few of the
                  Frequenty asked questions we here at Whissh.
                </p>
              </div>

              {data &&
                data.map((item, index) => (
                  <React.Fragment key={index}>
                    <div className="mt-20">
                      <p className="title-2 text-center">{item.title}</p>
                    </div>
                    <Accordion allowZeroExpanded={true}>
                      {item.faq.map((item2, index2) => (
                        <AccordionItem key={index2}>
                          <AccordionItemHeading>
                            <AccordionItemButton>{item2.q}</AccordionItemButton>
                          </AccordionItemHeading>
                          <AccordionItemPanel>
                            <p className="description">{item2.a}</p>
                          </AccordionItemPanel>
                        </AccordionItem>
                      ))}
                    </Accordion>
                  </React.Fragment>
                ))}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}