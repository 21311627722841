import React, { useContext } from "react";
import { BookingDetailsContext } from "../../views/MyAccount/MyBookingDetails";
import { useHistory } from "react-router-dom";
import { DateFormat } from "../../utils/common";

import { Button } from "reactstrap";
export default function MBHistoryDetails(props) {
    const bdContext = useContext(BookingDetailsContext);
    const history = useHistory();
    //const transferData = [];

    const FnRebookService = async e => {
        e.preventDefault()
        if (bdContext.BDState.BookingDetails.length > 0) {
            var data = bdContext.BDState.BookingDetails[0];

            // transferData.push({ 'key': 'bathroomqty', 'value': (data.matnum).toString() });
            // transferData.push({ 'key': '', 'value': (data.matnum).toString() });
            // transferData.push({ 'key': '', 'value': (data.matnum).toString() });
            // transferData.push({ 'key': '', 'value': (data.matnum).toString() });
            // transferData.push({ 'key': '', 'value': (data.matnum).toString() });

            // storeData('transferredData', transferData)
            history.push({
                pathname: '/service-booking',
                state: { 'jobnum': data.jobnum, 'matname': data.matname, 'matnum': data.matnum, 'baseamt': data.baseamt }
            });
        }
    }


    return (
        <>
            <div className="card">
                {bdContext.BDState.BookingDetails &&
                    <React.Fragment>
                        {
                            bdContext.BDState.BookingDetails.length < 1 ?
                                <div className="text-center empty-screen">No Records Found</div>
                                :
                                <React.Fragment>
                                    <div className="card-body">
                                        <div>
                                            {bdContext.BDState.BookingDetails &&
                                                <React.Fragment>
                                                    {
                                                        bdContext.BDState.BookingDetails.map((item, index) => (
                                                            <React.Fragment key={index}>
                                                                <div className="text-center">
                                                                    <img
                                                                        alt="Whissh Image"
                                                                        className="rounded-circle"
                                                                        src={require("../../assets/img/user-default.png")}
                                                                        style={{ maxHeight: "125px" }}
                                                                    ></img>
                                                                </div>
                                                                <div className="text-center title-2">{item.crewname} </div>
                                                                {item.crewname.trim().toLowerCase() !== "not assigned" &&
                                                                    <React.Fragment>
                                                                        <div className="text-center customer-rating">{item.rating}</div>
                                                                        <div className="text-center title-3">Rating</div>
                                                                    </React.Fragment>
                                                                }
                                                            </React.Fragment>
                                                        ))
                                                    }
                                                </React.Fragment>
                                            }
                                            <div className="text-center">
                                                <span className="btn-link btn btn-info p-0"
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        //props.Function.FnSetRenderModalContentType("SUBMIT_FEEDBACK");
                                                        bdContext.BDDispatch({ type: 'MODAL', modalType: 'SUBMIT_FEEDBACK', modalSize: 'lg' });
                                                    }}
                                                ><b>SUBMIT FEEDBACK</b></span>
                                                {/* |<span className="btn-link btn btn-info p-0"
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        //props.Function.FnSetRenderModalContentType("REFUND_REQUEST");
                                                        bdContext.BDDispatch({ type: 'MODAL', modalType: 'REFUND_REQUEST', modalSize: 'lg' });
                                                    }}
                                                ><b>
                                                        REQUEST REFUND</b></span> */}
                                            </div>
                                        </div>
                                        <div className="table-responsive table-list-view" id="myBookingDetailsTable">
                                            <table className="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th className="text-left">Booking Status</th>
                                                        <th className="text-left">Modified By</th>
                                                        <th className="text-left">Reason for changes</th>
                                                        <th className="text-left">Modifield On</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        bdContext.BDState.BookingDetails.map((item, index) => (
                                                            <tr key={index}>
                                                                <td className={item.status === "Open" ? "pending text-left" : "confirmed text-left"}>{item.status}</td>
                                                                <td className="text-left">{item.modifiedby}</td>
                                                                <td className="text-left">{item.reason}</td>
                                                                <td className="text-left contactName">{DateFormat(item.modifiedon, 'DD MMM YYYY h:mm a')}</td>
                                                            </tr>
                                                        ))
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                        {bdContext.BDState.BookingDetails[0].cusremarks && (
                                            <div className="text-left">
                                                Remarks: {bdContext.BDState.BookingDetails[0].cusremarks}
                                            </div>
                                        )
                                        }
                                    </div>
                                    {bdContext.BDState.BookingDetails[0].matnum != 'GC_Home' &&
                                        <div className="card-footer text-center">
                                            <Button
                                                className={"btn btn-round btn-info"}
                                                // to="/my-account/my-booking" tag={Link}
                                                onClick={e => {
                                                    FnRebookService(e);
                                                }}
                                            >
                                                REBOOK SERVICE
                                            </Button>
                                        </div>}
                                </React.Fragment>
                        }
                    </React.Fragment>
                }
            </div>

        </>
    )
}