import React, { useEffect, useState, useContext } from "react";
import {
  //Card,
  Container,
  Row,
  Col
} from "reactstrap";

function HomepageContent() {
  return (
    <>
      <div class="justify-content-center row">
        <div class="col-md-12">
          <div class="carousel slide">
            <ol class="carousel-indicators">
              <li class=""></li>
              <li class="active"></li>
            </ol>
            <div class="carousel-inner">
              <div class="home-carousel-inner carousel-item">
                <img src="https://d3m4ievhpgl874.cloudfront.net/Home-Low.jxr" alt="Banner home" />
              </div>
              <div class="home-carousel-inner carousel-item active">
                <img src="https://whisshcontent.s3.ap-southeast-1.amazonaws.com/Yacht - Low" alt="Banner Yacht" />
              </div>
            </div>
            <a class="carousel-control-prev" data-slide="prev" href="#pablo" role="button">
              <i class="now-ui-icons arrows-1_minimal-left"></i>
            </a>
            <a class="carousel-control-next" data-slide="next" href="#pablo" role="button">
              <i class="now-ui-icons arrows-1_minimal-right"></i>
            </a>
          </div>
          <div class="home-carousel-caption">
            <div id="menu-dropdown">
              <div class="title">
                <div id="navbar-menu">
                  <nav class="navbar-default navbar navbar-expand-lg">
                    <ul class="adjust-menu-space navbar-nav">
                      <li class="dropdown nav-item">
                        <button type="button" data-toggle="dropdown" btn-value="Home Cleaning" aria-haspopup="true" aria-expanded="false" class="btn-round dropdown-toggle btn btn-default">Home Cleaning </button>
                        <div tabindex="-1" role="menu" aria-hidden="true" class="sub-menu-container dropdown-menu">
                          <a href="#pablo" tabindex="0" role="menuitem" class="dropdown-item">
                            <div class="row" style={{ width: '100%' }}>
                              <div class="menu-image">
                                <img alt="Home Cleaning" src="https://whisshcontent.s3.ap-southeast-1.amazonaws.com/ERP/SVCM/Home Cleaning/1599756079.jpg" />
                              </div>
                              <div class="menu-desc">
                                <div class="text-left">
                                  <div>
                                    <div class="menu-title">Home Weekly Cleaning</div>
                                    <div class="menu-content">Regular housekeeping for home cleaning. 4 hours per session. e.g. Holland Area</div>
                                    <div class="menu-link">More Info</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </a>
                          <a href="#pablo" tabindex="0" role="menuitem" class="dropdown-item">
                            <div class="row" style={{ width: '100%' }}>
                              <div class="menu-image">
                                <img alt="Home Cleaning" src="https://whisshcontent.s3.ap-southeast-1.amazonaws.com/ERP/SVCM/Home Cleaning/1599756053.jpg" />
                              </div>
                              <div class="menu-desc">
                                <div class="text-left">
                                  <div>
                                    <div class="menu-title">Home Deep Cleaning </div>
                                    <div class="menu-content">Occasional demands (e.g.Spring cleaning, move-in, move-out, etc.)</div>
                                    <div class="menu-link">More Info</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </a>
                        </div>
                      </li>
                      <li class="dropdown nav-item">
                        <button type="button" data-toggle="dropdown" btn-value="Office Cleaning" aria-haspopup="true" aria-expanded="false" class="btn-round dropdown-toggle btn btn-default">Office Cleaning</button>
                        <div tabindex="-1" role="menu" aria-hidden="true" class="sub-menu-container dropdown-menu">
                          <a href="#pablo" tabindex="0" role="menuitem" class="dropdown-item">
                            <div class="row" style={{ width: '100%' }}>
                              <div class="menu-image">
                                <img alt="Vacuum cleaner" src="https://whisshcontent.s3.ap-southeast-1.amazonaws.com/ERP/SVCM/Office Cleaning/1599757402.jpg" />
                              </div>
                              <div class="menu-desc">
                                <div class="text-left">
                                  <div>
                                    <div class="menu-title">Office Cleaning </div>
                                    <div class="menu-content">Office cleaning for regular cleaning... Raffles Place, Marina Bay Financial Centre</div>
                                    <div class="menu-link">More Info</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </a>
                          <a href="#pablo" tabindex="0" role="menuitem" class="dropdown-item">
                            <div class="row" style={{ width: '100%' }}>
                              <div class="menu-image">
                                <img alt="" src="https://whisshcontent.s3.ap-southeast-1.amazonaws.com/ERP/SVCM/Office Cleaning/1599757420.jpg" />
                              </div>
                              <div class="menu-desc">
                                <div class="text-left">
                                  <div>
                                    <div class="menu-title">Commercial Building Cleaning </div>
                                    <div class="menu-content">Whole building cleaning..Orchard, Jurong, Changi, Industrial Estate</div>
                                    <div class="menu-link">More Info</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </a>
                        </div>
                      </li>
                      <li class="dropdown nav-item">
                        <button type="button" data-toggle="dropdown" btn-value="Aircon" aria-haspopup="true" aria-expanded="false" class="btn-round dropdown-toggle btn btn-default">Aircon Maintenance</button>
                        <div tabindex="-1" role="menu" aria-hidden="true" class="sub-menu-container dropdown-menu">
                          <a href="#pablo" tabindex="0" role="menuitem" class="dropdown-item">
                            <div class="row" style={{ width: '100%' }}>
                              <div class="menu-image">
                                <img alt="Air conditioner" src="https://whisshcontent.s3-ap-southeast-1.amazonaws.com/1595585597.jpg" />
                              </div>
                              <div class="menu-desc">
                                <div class="text-left">
                                  <div>
                                    <div class="menu-title">Aircon Services</div>
                                    <div class="menu-content">Reliable and clean home aircon and office aircon services</div>
                                    <div class="menu-link">More Info</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </a>
                        </div>
                      </li>
                      <li class="dropdown nav-item">
                        <button type="button" data-toggle="dropdown" btn-value="Other Svcs" aria-haspopup="true" aria-expanded="false" class="btn-round dropdown-toggle btn btn-default">Others, e.g. disinfection...</button>
                        <div tabindex="-1" role="menu" aria-hidden="true" class="sub-menu-container dropdown-menu">
                          <a href="#pablo" tabindex="0" role="menuitem" class="dropdown-item">
                            <div class="row" style={{ width: '100%' }}>
                              <div class="menu-image">
                                <img alt="Clothes" src="https://whisshcontent.s3.ap-southeast-1.amazonaws.com/ERP/SVCM/Other Svcs/1599821254.jpg" />
                              </div>
                              <div class="menu-desc">
                                <div class="text-left">
                                  <div>
                                    <div class="menu-title">Laundry</div>
                                    <div class="menu-content">Detailed and careful Laundry Services </div>
                                    <div class="menu-link">More Info</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </a>
                          <a href="#pablo" tabindex="0" role="menuitem" class="dropdown-item">
                            <div class="row" style={{ width: '100%' }}>
                              <div class="menu-image">
                                <img alt="Disinfection Cleaning" src="https://whisshcontent.s3.ap-southeast-1.amazonaws.com/ERP/SVCM/Other Svcs/1603476552.jpg" />
                              </div>
                              <div class="menu-desc">
                                <div class="text-left">
                                  <div>
                                    <div class="menu-title">Disinfection</div>
                                    <div class="menu-content">Thorough and assured</div>
                                    <div class="menu-link">More Info</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </a>
                          <a href="#pablo" tabindex="0" role="menuitem" class="dropdown-item">
                            <div class="row" style={{ width: '100%' }}>
                              <div class="menu-image">
                                <img alt="Electrician" src="https://whisshcontent.s3.ap-southeast-1.amazonaws.com/ERP/SVCM/Other Svcs/1599757830.jpg" />
                              </div>
                              <div class="menu-desc">
                                <div class="text-left">
                                  <div>
                                    <div class="menu-title">Electrical</div>
                                    <div class="menu-content">Qualified and Safe electrical work </div>
                                    <div class="menu-link">More Info</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </a>
                          <a href="#pablo" tabindex="0" role="menuitem" class="dropdown-item">
                            <div class="row" style={{ width: '100%' }}>
                              <div class="menu-image">
                                <img alt="CCTV Camera" src="https://whisshcontent.s3.ap-southeast-1.amazonaws.com/ERP/SVCM/Other Svcs/1599758762.jpg" />
                              </div>
                              <div class="menu-desc">
                                <div class="text-left">
                                  <div>
                                    <div class="menu-title">SMART Building</div>
                                    <div class="menu-content">Security, Energy Management </div>
                                    <div class="menu-link">More Info</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </a>
                          <a href="#pablo" tabindex="0" role="menuitem" class="dropdown-item">
                            <div class="row" style={{ width: '100%' }}>
                              <div class="menu-image">
                                <img alt="Bathroom Cleaning" src="https://whisshcontent.s3.ap-southeast-1.amazonaws.com/ERP/SVCM/Other Svcs/1599758093.jpg" />
                              </div>
                              <div class="menu-desc">
                                <div class="text-left">
                                  <div>
                                    <div class="menu-title">Plumbing</div>
                                    <div class="menu-content">Clean and Lasting plumbing effort</div>
                                    <div class="menu-link">More Info</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </a>
                          <a href="#pablo" tabindex="0" role="menuitem" class="dropdown-item">
                            <div class="row" style={{ width: '100%' }}>
                              <div class="menu-image">
                                <img alt="" src="https://www.whissh.com.sg//static/media/logo.35a21377.png" />
                              </div>
                              <div class="menu-desc">
                                <div class="text-left">
                                  <div>
                                    <div class="menu-title">Carpet Cleaning</div>
                                    <div class="menu-content">Revitalizing carpets</div>
                                    <div class="menu-link">More Info</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </a>
                        </div>
                      </li>
                      <li class="dropdown nav-item">
                        <button type="button" data-toggle="dropdown" btn-value="AM and FM " aria-haspopup="true" aria-expanded="false" class="btn-round dropdown-toggle btn btn-default">Asset/Facilities Mgt</button>
                        <div tabindex="-1" role="menu" aria-hidden="true" class="sub-menu-container dropdown-menu">
                          <a href="#pablo" tabindex="0" role="menuitem" class="dropdown-item">
                            <div class="row" style={{ width: '100%' }}>
                              <div class="menu-image">
                                <img alt="Pool Cleaning" src="https://whisshcontent.s3.ap-southeast-1.amazonaws.com/ERP/SVCM/AM and FM /1599758449.jpg" />
                              </div>
                              <div class="menu-desc">
                                <div class="text-left">
                                  <div>
                                    <div class="menu-title">Asset/Faciltities Management</div>
                                    <div class="menu-content">Enhance your asset through hassel-free leasing and integrated facilities management. </div>
                                    <div class="menu-link">More Info</div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </a>
                        </div>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div class="features-3 pt-0" style={{ marginTop: "20px" }}>
          <div class="ml-auto mr-auto col-md-8">
            <div class="big-heading">An Eco-Friendly Company</div>
            <hr class="hr-large" />
            <span class="description">Whissh prides ourselves on being the 1st and only service provider in the industry to use Eco-Friendly cleaning products for all our deep cleaning services!<br></br>By popular demand, Whissh Eco-Friendly cleaning products are made available for sale as well<br></br></span>
            <div>
              <img alt="Whissh Eco Friendly" class="rounded" src="https://whisshcontent.s3.ap-southeast-1.amazonaws.com/Whissh_ECO_Friendly_Cleaning_Product2.jpg" />
            </div>
          </div>
        </div>
        <div class="EasyStep-3">
          <div class="container">
            <div class="row">
              <div class="ml-auto mr-auto text-center col-md-8">
                <h1 class="big-heading">How It Works In 3 Easy Steps!</h1>
                <hr class="hr-large" /><p class="description">Seamless process for on-demand home service</p>
              </div></div><div class="row"><div class="col-md-4"><div class="info info-hover">
                <div class="icon icon-success icon-circle"><img alt="1" src="https://whisshcontent.s3.ap-southeast-1.amazonaws.com/Step1.png" />
                </div>
                <div class="details">
                  <div class="title">Book easily online</div>
                  <p class="description">Book online with instant confirmation &amp; we'll match you with a trusted, experienced house cleaner.</p>
                </div>
              </div>
              </div>
              <div class="col-md-4"><div class="info info-hover"><div class="icon icon-primary icon-circle">
                <img alt="2" src="https://whisshcontent.s3.ap-southeast-1.amazonaws.com/Step2.png" />
              </div>
                <div class="details">
                  <div class="title">Confirm and pay securely</div>
                  <p class="description">Select date, time and pay securely online by credit card.</p>
                </div>
              </div>
              </div>
              <div class="col-md-4"><div class="info info-hover"><div class="icon icon-info icon-circle">
                <img alt="3" src="https://whisshcontent.s3.ap-southeast-1.amazonaws.com/Step3.png" />
              </div>
                <div class="details">
                  <div class="title">Get a 5 star cleaner</div>
                  <p class="description">Your trusted service crew will arrive at your home on time. Every cleaner is friendly and reliable.</p>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
        <div class="EasyStep-section features-8 section-image">
          <img alt="Whissh Image" class="rounded" src="https://whisshcontent.s3.ap-southeast-1.amazonaws.com/1593110879.jxr" />
        </div>
      </div>
      <div className="cd-section" id="customer">
        <div
          style={{
            backgroundImage: "url(" + require("../../assets/img/bg-gray-cpg.jpg") + ")",
            padding: "1px 0 1px",
            backgroundRepeat: "round"
          }}
        >
          <Container>
            <Row>
              <div className="ml-auto mr-auto text-center"
                style={{ paddingBottom: "30px" }}>
                <div className="big-heading">Customer Protection Guarantee</div>
                <hr className="hr-large"></hr>
                <p className="description">
                  Your satisfaction matters, so we take additional steps to ensure that you are happy with the results.
                </p>
              </div>
            </Row>
            <Row>
              <Col md="4">
                <div className="info info-hover text-md-right text-xs-left pb-10">
                  <div>
                    <p className="title-2">Quality Assurance</p>
                    <p className="description">
                      Our review system ensure you won't be matched with the service crews you had previously poorly rated.
                    </p>
                  </div>
                  <div>
                    <p className="title-2">Highly Trained</p>
                    <p className="description">
                      All service crews have gone through hours of training by certified industry experts.
                    </p>
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div className="text-center">
                  <img
                    alt="2"
                    src={require("../../assets/img/icon-thebest.png")}
                    style={{ height: "150px" }}
                  ></img>
                </div>
              </Col>
              <Col md="4">
                <div className="info info-hover text-md-left text-xs-left">
                  <div>
                    <p className="title-2">Insured up to $250,000</p>
                    <p className="description">
                      For accidents,either property or accidents, either property or personal injuiry to the service crews.
                    </p>
                  </div>
                  <div>
                    <p className="title-2">100% Screening</p>
                    <p className="description">
                      Background and health checks conducted on all service crews.
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <div class="features-3 pt-0">
        <div class="container">
          <div class="row">
            <div class="ml-auto mr-auto col-md-8">
              <div class="big-heading">Over 100,000 have chosen Whissh</div>
              <hr class="hr-large" />
              <span class="description">We are proud to serve clients from</span>
              <div class="countries-section features-8 section-image">
                <img alt="Whissh Image" class="rounded" src="https://d3m4ievhpgl874.cloudfront.net/International Customers 2.jxr" />
              </div>
            </div>
          </div>
          <div class="ml-auto mr-auto row" style={{ marginTop: "25px" }}>
            <div class="ml-auto mr-auto text-center col-md-6">
              <div class="react-multi-carousel-list carousel-container ">
                <ul class="react-multi-carousel-track ">
                  <li data-index="0" aria-hidden="false" class="react-multi-carousel-item react-multi-carousel-item--active " style={{ flex: 1, position: "relative", width: "87" }}></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="ml-auto mr-auto text-center" style={{ backgroundColor: "rgb(75, 181, 255)" }}>
        <div class="card-title bookNow-title">
          <b style={{ color: "rgb(255, 255, 255)" }}>Book a Home Service with us in 60 seconds</b>
          <a class="btn-round btn btn-default btn-book-now" href="/corporate-enquiry">
            <i class="fas fa-bell pr-10"></i>Book Now </a>
        </div>
      </div>
      <div class="text-left">
        <b>“Spend your precious and happy moments with your family and leave your cleaning chores to Whissh; you have come to the right place for all your cleaning needs.”</b>
        <br />
        <br /> All of us wish to live in a clean house but keeping your home clean all the time is never easy, as it is a full-time job. It seems impossible to keep it clean daily because it consumes most of your leisure time. The services of professional house cleaners may be able to help with this problem. <br />
        <br /> When it comes to residential cleaning, we must consider the amount of dust that enters our homes regularly. Residential areas need regular cleaning services because this is where we spend the majority of our time. Cleaning our homes regularly is a total nightmare and a time-consuming job. <br />
        <br /> Why not employ Whissh Singapore to handle all of your residential cleaning issues? <br />
        <br /> We are Singapore's number one cleaning company, providing high-quality Home Cleaning Services. Many of our previous customers and clients have given us high marks for our House Cleaning Services. This is because we clean your homes with the most up-to-date cleaning technology, making it appear newer. <br />
        <br />
        <b>What makes us unique?</b>
        <br />
        <br /> Many prominent features make us one of the most reputed Cleaning Services in Singapore: <br />
        <br />
        <b>Certified Professional Team:</b>
        <br />
        <br /> We have a team of highly qualified and certified staff to provide better facilities. All of our employees are trained with years of cleaning experience. As a result, you can rest assured that when our team of experts finishes cleaning your house, it will look brand new. <br />
        <br />
        <b>Latest cleaning equipment:</b>
        <br />
        <br /> Having a team of highly skilled cleaning professionals working with antiquated cleaning equipment is pointless! We use the most up-to-date cleaning equipment to provide state-of-the-art Home Cleaning Services. These machines are capable of eliminating all clutter, odors, and dirt, even if it has sunk deep into the fibers of your carpet or sofa. Our experts would remove even the stains that you felt were impossible to remove with these tools. <br />
        <br />
        <b>Our rate is competitive:</b>
        <br />
        <br /> While looking for housecleaning services, you might discover that they do not precisely meet your needs, and the prices they quote you are too high. This is not the case with us; we provide our House Cleaning Services at reasonable prices. Furthermore, we offer protection and dependability, which you might not get when hiring an independent cleaner. <br />
        <br />
        <b>We are reliable:</b>
        <br />
        <br /> It's important to make an informed decision on who to hire for your House Cleaning Services. You're inviting a stranger into your home, so think about hiring someone who will not only be fast and effective, but also trustworthy with your valuables and provide a professional service.&nbsp; <br />
        <br />
        <b>Many health benefits:</b>
        <br />
        <br /> We are proud of our ability to reduce bacteria, allergens, and other germs that can spread illness in your home as a professional cleaning company. Our residential housekeepers are equipped with the tools needed to eliminate airborne viruses and allergens from your home. <br />
        <br />
        <b>Our Values</b>
        <br />
        <br />
        <ul>
          <li>Our ambition:&nbsp;It is for you to be fully satisfied. We want you to have the best time possible while we take care of the rest.</li>
          <li>Professionalism:&nbsp;We pride ourselves on being reliable, setting high targets for ourselves, and demonstrating that we care.</li>
          <li>Respect:&nbsp;Treat others with the same compassion, courtesy, and politeness with which you would like to be handled. Encourage your colleagues to share their innovative ideas with you.</li>
          <li>Diligence: Careful and diligent effort, working toward our goals continuously, using whatever tools and opportunities are available.</li>
          <li>Quality of work:&nbsp;We provide quality of work with accuracy, correctness, and competence where we strive to increase productivity level, time management, ability to meet deadlines.</li>
          <li>Service-oriented:&nbsp;It entails delivering services that make effective use of technology to achieve better results. Making this change requires placing customer needs and priorities at the center of the organization's vision, culture, and operations.</li>
        </ul>
        <br />
        <b>Our Future Aim:</b>
        <br />
        <br /> We are currently expanding to Tokyo and other major cities to promote sustainable living in gateway cities worldwide. We want to use digitally powered services to transform and improve the standard of home living, allowing globally mobile people to live a hassle-free lifestyle while also increasing the value of build-to-rent properties. <br />
        <br />
        <b>Professional Cleaning Services in Singapore</b>
        <br />
        <br /> Above and beyond providing cleaning services in Singapore, Whissh is a one-stop service provider of quality home and office services. Established in the cleaning industry of Singapore, we offer other home and office services as well. This includes aircon, laundry, upholstery services and more. <br /> Whissh provides professional cleaning services in Singapore. Our crew are hired only after face-to-face interviews have been conducted. They then undergo customer service training and certified cleaning courses by industry experts. If you are an agent agonising over the varying conditions of the properties handed back to you in unsatisfactory conditions, you may wish to engage Whissh for professional handover cleaning services for quality assurance. <br />
        <br />
        <b>An Eco-Friendly Cleaning Company Singapore</b>
        <br />
        <br /> Whissh is the first in the industry in Singapore to use eco-friendly cleaning products in our services. We do more than just pay lip service to the eco lifestyle. It is a firm pillar of belief in our company and presents itself in our deep cleaning services, as well as our aircon services. To us, eco is more than just a trend; it is a lifestyle. Here at Whissh we have our future generations in mind and believe it is necessary to live sustainably. Join us on our journey of sustainable living. <br />
        <br />
        <b>Affordable Home Services Singapore</b>
        <br />
        <br /> Whissh might not be the cheapest company in Singapore, however we are an affordable one-stop professional home service provider. We are a company that does not compromise on our quality. Our professionally trained team of crew provide a wide range of services from home cleaning services to disinfecting services. Your cleaning experience with Whissh will be value for money – arguably a more important trait than cheap prices. <br />
        <br /> Our spring cleaning service is the most popular service; they include cleaning of hard to reach areas such as ceiling fans, and tedious to clean areas such as windows. Sit back with a peace of mind while our team of experts work on your home. <br />
      </div></>
  )
}

export default HomepageContent;
