import React, { useState, useContext, useEffect } from 'react';
import { Button, ModalBody, ModalHeader, Row, Col } from 'reactstrap';
// import { GlobalContext } from 'App.js';
import { ServiceBookingContext } from '../../views/ServiceBooking';
import { getGreetingTime, DateFormat, TimeFormat } from "../../utils/common";
import { ReadRate } from '../../utils/common';
// const sbContext = useContext(ServiceBookingContext);
function ModalPackageDetails(props) {
    // const globalContext = useContext(GlobalContext);
    const sbContext = useContext(ServiceBookingContext);
    const [PackageDetails, setPackageDetails] = useState({});
    useEffect(() => {
        document.querySelector('.modal').scrollTo({
            top: 0,
            behavior: 'smooth'
        });
        setPackageDetails(sbContext.ServiceState.PackageDetails)
        // console.log(sbContext.ServiceState.PackageDetails)
    }, [])

    return (
        <>
            <div className="package-details-modal">
                <ModalHeader toggle={() => sbContext.ServiceDispatch({ type: 'CLOSE_MODAL' })}>
                </ModalHeader>
                <ModalBody className="p-0">

                    <div className="card">
                        <div className="card-header text-left title-2">Booking Information</div>
                        {!PackageDetails ? <div className="text-center empty-screen">Cart Details Not Available</div> :
                            <div className="card-body">
                                <Row>
                                    <Col md="3" xs="12" sm="12" className="ml-auto mr-auto ">
                                        <p className="title-3">Package Information</p>
                                        <hr></hr>
                                        <div>
                                            <div className="other-services-card text-center">
                                                <div className="card-header text-center title-3">
                                                    {/* Weekly Cleaning */}
                                                    {PackageDetails.matname}
                                                </div>

                                                <div className="m-0 p-20">
                                                    {
                                                        PackageDetails.rate ?
                                                            <React.Fragment>
                                                                {ReadRate(PackageDetails.rate).beforerate}<br></br>
                                                                <span className="dollor">$</span>
                                                                <span className="price">{ReadRate(PackageDetails.rate).rate}</span><br></br>
                                                                {ReadRate(PackageDetails.rate).afterrate}
                                                            </React.Fragment>
                                                            : null
                                                    }
                                                </div>
                                                <hr></hr>
                                                <div>
                                                    {PackageDetails.duration} Hours, {PackageDetails.sessionQty} sessions
                                                </div>
                                                <hr className="mb-0"></hr>
                                                <div>
                                                    {PackageDetails.frequency}
                                                </div>
                                                <hr className="mb-0"></hr>
                                                <Button type="button" className="btn-round btn btn-info"
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        // props.Function.FnSetRenderComponentType("DATE_TIME");
                                                        sbContext.BookingDispatch({ type: 'SET_DATA', payload: PackageDetails });
                                                        sbContext.ServiceDispatch({ type: 'DATE_TIME' });
                                                    }}
                                                >
                                                    <span className="bookNow">Change</span>
                                                </Button>
                                            </div>
                                        </div >

                                    </Col>
                                    <Col md="3" xs="12" sm="12" className="ml-auto mr-auto ">
                                        <p className="title-3">Package add-ons</p>
                                        {/* <p className="title-3">Package add-ons</p>
                                        <hr></hr>
                                        <div>
                                            {
                                                PackageDetails.addon &&
                                                PackageDetails.addon.split("||").map((item, index) => (
                                                    <b key={index}>
                                                        {item}<br />
                                                    </b>
                                                ))
                                            }
                                        </div>
                                        <Button type="button" className="btn-round btn btn-info"
                                            onClick={e => {
                                                e.preventDefault();
                                                sbContext.ServiceDispatch({ type: 'PACKAGE_ADD_ONS', payload: PackageDetails, callback: trigger });
                                            }}>
                                            <span className="bookNow">MODIFY ADD ONS</span>
                                        </Button> */}
                                        {PackageDetails.hasAddon &&
                                            <React.Fragment>
                                                {PackageDetails.addonList && PackageDetails.addonList.length > 0 ?
                                                    <React.Fragment>
                                                        <hr></hr>
                                                        <div>
                                                            {
                                                                PackageDetails.addonList.map((item, index) => (
                                                                    // PackageDetails.addon.split("||").map((item, index) => (
                                                                    <b key={index}>
                                                                        {item.desc}<br />
                                                                    </b>
                                                                ))
                                                            }
                                                        </div>
                                                        <button
                                                            className={"btn btn-round btn-info"}
                                                            onClick={e => {
                                                                e.preventDefault();
                                                                {
                                                                    PackageDetails.isMultijob ?
                                                                        sbContext.ServiceDispatch({ type: 'MLT_PACKAGE_ADD_ONS', payload: PackageDetails, callback: sbContext.ServiceState.callback })
                                                                        :
                                                                        sbContext.ServiceDispatch({ type: 'PACKAGE_ADD_ONS', payload: PackageDetails, callback: sbContext.ServiceState.callback })
                                                                }
                                                            }}>
                                                            MODIFY ADD-ON
                                             </button>
                                                    </React.Fragment>
                                                    :
                                                    <button
                                                        className={"btn btn-round btn-info"}
                                                        onClick={e => {
                                                            e.preventDefault();
                                                            {
                                                                PackageDetails.isMultijob ?
                                                                    sbContext.ServiceDispatch({ type: 'MLT_PACKAGE_ADD_ONS', payload: PackageDetails, callback: sbContext.ServiceState.callback })
                                                                    :
                                                                    sbContext.ServiceDispatch({ type: 'PACKAGE_ADD_ONS', payload: PackageDetails, callback: sbContext.ServiceState.callback })
                                                            }
                                                        }}>
                                                        ADD-ON
                                         </button>
                                                }
                                            </React.Fragment>
                                        }

                                    </Col>
                                    <Col md="3" xs="12" sm="12" className="ml-auto mr-auto">
                                        <p className="title-3">Property Information</p>
                                        <hr></hr>
                                        <div>
                                            <b className="text-left">
                                                Property Tyoe
                                        </b>
                                            <p className="description">
                                                {PackageDetails.shiphousetype}({PackageDetails.shipsize} sqft)
                                        </p>
                                        </div>
                                        <div>
                                            <b className="text-left">
                                                No. of bedroom
                                        </b>
                                            <p className="description">
                                                {PackageDetails.shipbedroomQty}
                                            </p>
                                        </div>
                                        <div>
                                            <b className="text-left">
                                                No. of bathroom
                                            </b>
                                            <p className="description">
                                                {PackageDetails.shipbathroomQty}
                                            </p>
                                        </div>
                                        <br />
                                        <p className="title-3">Date & Time Slot</p>
                                        <hr></hr>
                                        {/* <div>
                                            <b className="text-left">
                                                Preferred Date
                                            </b>
                                            <p className="description">
                                                {DateFormat(PackageDetails.startdate, 'ddd, DD-MMM-YYYY')}
                                            </p>
                                        </div>
                                        <div>
                                            <b className="text-left">
                                                Preferred Time
                                            </b>
                                            <p className="description">
                                                {getGreetingTime(PackageDetails.starttime, PackageDetails.endtime)} {TimeFormat(PackageDetails.starttime)}-{TimeFormat(PackageDetails.endtime)}
                                            </p>
                                        </div> */}
                                        <div>
                                            <b className="text-left">
                                                Schedule
                                            </b>
                                            <p className="description" style={{whiteSpace:"pre"}}>
                                                {PackageDetails.schedule}
                                            </p>
                                        </div>
                                    </Col>
                                    <Col md="3" xs="12" sm="12" className="ml-auto mr-auto ">
                                        <p className="title-3">Contact Information</p>
                                        <hr></hr>
                                        <div>
                                            <b className="text-left">
                                                Name
                                            </b>
                                            <p className="description">
                                                {PackageDetails.shipfirstname}   {PackageDetails.shiplastname}
                                            </p>
                                        </div>
                                        <div>
                                            <b className="text-left">
                                                Address
                                            </b>
                                            <p className="description">
                                                {PackageDetails.shipfulladdr}
                                            </p>
                                        </div>
                                        <div>
                                            <b className="text-left">
                                                Phone No.
                                            </b>
                                            <p className="description">
                                                {PackageDetails.shipphone}
                                            </p>
                                        </div>
                                        <div>
                                            <b className="text-left">
                                                Email
                                            </b>
                                            <p className="description">
                                                {PackageDetails.shipemail}
                                            </p>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        }
                    </div>
                </ModalBody>
            </div>
        </>
    );
}

export default ModalPackageDetails;